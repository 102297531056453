<template>
  <div class="story-campaign-actions">
    <AppLink
      v-if="story.guideUrl"
      class="btn border-btn story-campaign-actions__btn"
      data-aos="fade-up"
      :to="story.guideUrl"
      >{{ story.guideName }}</AppLink
    >
    <AppLink
      v-if="story.museumAccessUrl"
      class="btn border-btn story-campaign-actions__btn"
      data-aos="fade-up"
      :to="story.museumAccessUrl"
      >Your Museum Access</AppLink
    >
    <button
      v-if="showArtFairAttendBtn"
      class="btn border-btn story-campaign-actions__btn"
      data-aos="fade-up"
      type="button"
      @click="handleArtFairRequest"
    >
      Get your passes
    </button>
  </div>
</template>

<script>
import AppLink from '@/components/AppLink.vue';

export default {
  name: 'StoryCampaignActions',
  components: { AppLink },
  props: {
    showArtFairAttendBtn: {
      type: Boolean,
      default: false,
    },
    story: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleArtFairRequest() {
      this.$emit('request-art-fair');
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  margin: 50px auto;

  @media (min-width: 577px) {
    margin: 70px auto;
  }

  @media (min-width: 992px) {
    gap: 60px;
  }

  &__btn {
    display: block;
    max-width: 410px;
    font-size: 18px;
  }
}
</style>
