<template>
  <div
    class="art-guide-reorder-control-events-list"
    :class="{ 'art-guide-reorder-control-events-list--scrollable': scrollable }"
  >
    <Draggable
      v-model="vModel"
      v-bind="dragOptions"
      class="events-list"
      tag="ul"
      @start="isDragging = true"
      @end="isDragging = false"
    >
      <li v-for="event in vModel" :key="event.id" class="events-list-item">
        <figure class="events-list-item__content">
          <div class="content-image-section">
            <div class="content-image-section__left">
              <img v-if="event.media" :src="event.media.url" :alt="event.title" />
            </div>
            <div class="content-image-section__right">
              <ArtGuideEventsReorderControlHandle class="events-reorder-handle" />
            </div>
          </div>
          <figcaption class="event-title">{{ event.title }}</figcaption>
        </figure>
      </li>
    </Draggable>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ArtGuideEventsReorderControlHandle from '@/components/artguide/ArtGuideEventsReorderControlHandle.vue';

export default {
  components: {
    Draggable,
    ArtGuideEventsReorderControlHandle,
  },
  props: {
    scrollable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dragOptions: {
        animation: 200,
        chosenClass: 'events-list-item--chosen',
        disabled: false,
        ghostClass: 'events-list-item--ghost',
        group: 'events',
        handle: '.events-reorder-handle',
      },
      isDragging: false,
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-reorder-control-events-list {
  $listHeight: 110px;

  &--scrollable {
    padding-right: 16px;
  }

  .events-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    min-height: $listHeight;
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    @media (min-width: 533px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
    }

    @media (min-width: 641px) {
      grid-template-columns: repeat(4, 1fr);
    }

    &-item {
      width: 100%;
      max-height: $listHeight;
      padding: 0 0 6px 6px;
      overflow: hidden;
      border: 1px solid #777;
      text-align: center;

      &__content {
        margin: 0;

        .content-image-section {
          user-select: none;
          display: flex;
          justify-content: space-between;

          &__left {
            flex: 1;
          }
          &__right {
            width: 26px;
          }
        }

        img {
          width: 100%;
          height: 70px;
          margin-top: 6px;
          object-fit: cover;
        }

        .event-title {
          margin-top: 8px;
          padding-right: 6px;
          font-size: 14px;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &--chosen {
        background-color: #dadcde;
      }

      &--ghost {
        background-color: #f7f7f9;
        opacity: 0.7;
      }
    }
  }
}
</style>
