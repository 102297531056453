<template>
  <section class="art-guide-staff-pick-events-section">
    <h2 class="art-guide-staff-pick-events-section__header">Not to miss</h2>
    <ArtGuideStaffPickEvents :events="events" />
  </section>
</template>

<script>
import ArtGuideStaffPickEvents from '@/components/artguide/ArtGuideStaffPickEvents.vue';

export default {
  components: { ArtGuideStaffPickEvents },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-staff-pick-events-section {
  &__header {
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 3px;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 577px) {
      margin-bottom: 40px;
    }
  }
}
</style>
