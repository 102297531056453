<template>
  <div class="box swithers">
    <div v-if="isLoader" class="d-flex justify-content-center">
      <AppLoader />
    </div>
    <div class="d-flex justify-content-between flex-column flex-sm-row">
      <div class="name">Hide my name in member tips & reviews</div>
      <div class="d-flex justify-content-between">
        <div
          v-for="(item, index) in states"
          :key="`settings_state_${index}`"
          class="toogle-btn"
          :class="[{ 'ml-5': index === 1 }, { active: index === activeIndex }]"
          @click="onChange(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import meQuery from '@/graphql/me/Me.query.gql';
import changeUserSettingsMutation from '@/graphql/me/account/UpdateUserContactSettings.mutation.gql';

export default {
  name: 'ContactPreferencesSettings',
  data() {
    return {
      states: ['on', 'off'],
      activeIndex: -1,
      isLoader: false,
    };
  },
  apollo: {
    me: {
      query: meQuery,
      result({ data }) {
        if (data?.me?.settings) {
          this.activeIndex = data.me.settings.hide_name ? 0 : 1;
        }
      },
    },
  },
  methods: {
    onChange(index) {
      this.activeIndex = index;
      this.isLoader = true;
      this.$apollo
        .mutate({
          mutation: changeUserSettingsMutation,
          variables: {
            hide_name: this.activeIndex === 0,
          },
        })
        .then(({ data }) => {
          this.activeIndex = data.UsersSettingsRequest.settings.hide_name ? 0 : 1;
          this.isLoader = false;
        })
        .catch(() => {
          this.isLoader = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  color: #353535;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2.86px;
  max-width: 60%;
  @media (max-width: 768px) {
    max-width: none;
    width: 100%;
    margin-bottom: 15px;
  }
}

.active {
  font-weight: 700;
}

.toogle-btn {
  @extend .name;
  max-width: none;
  cursor: pointer;
}
</style>
