<template>
  <div class="create-art-guide-modal" @click.self="close">
    <div class="guide-modal-dialog">
      <div class="guide-modal-content">
        <button class="guide-modal-close" type="button" @click="close">
          <TheCrossIcon />
        </button>

        <section v-if="!finishCreate" class="guide-form-section">
          <header class="guide-form-section__header">
            <h3 class="guide-form-section__title">Create Art Guide</h3>
          </header>

          <form class="guide-form" @submit.prevent="handleSubmit">
            <CreateArtGuideFormInput
              v-model="title"
              :error="errors.title"
              label="Title"
              placeholder="Enter title"
              required
              @input="validateInput($event, 'title')"
            />
            <CreateArtGuideFormInput
              v-model="description"
              :error="errors.description"
              label="Description"
              placeholder="Enter description"
              @input="validateInput($event, 'description')"
            />
            <button class="guide-form__btn-submit" :disabled="isFormSubmitDisabled" type="submit">Create</button>
          </form>
        </section>

        <section v-else class="guide-form-section">
          <header class="guide-form-section__header">
            <h3 class="guide-form-section__title">Art Guide created</h3>
            <p class="guide-form-section__text">Start adding Events, Exhibitions, Museums, Galleries, and more.</p>
          </header>
          <RouterLink class="btn border-btn guide-form-section__action" :to="{ name: 'destinations' }"
            >Explore destinations</RouterLink
          >
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import createArtGuideMutation from '@/graphql/artGuide/CreateArtGuide.mutation.gql';

import CreateArtGuideFormInput from '@/components/artguide/CreateArtGuideFormInput.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  components: { CreateArtGuideFormInput, TheCrossIcon },
  data() {
    return {
      title: null,
      description: null,
      errors: {
        title: null,
        description: null,
      },
      isCreating: false,
      finishCreate: false,
      isFormValid: false,
    };
  },
  computed: {
    isFormSubmitDisabled() {
      return !this.isFormValid || this.isCreating;
    },
  },
  methods: {
    close() {
      if (this.isCreating) {
        return;
      }

      this.title = null;
      this.description = null;
      this.errors.title = null;
      this.errors.description = null;
      this.isFormValid = false;

      this.$emit('close');
    },
    validateInput(value, field) {
      if (!value && field === 'title') {
        this.isFormValid = false;
        this.errors[field] = 'Please, enter the title.';
        return;
      }

      if (!/^[\w\p{L}\-\s'’".,:;/#&()]*$/iu.test(value)) {
        this.isFormValid = false;
        this.errors[field] = 'Invalid format.';
        return;
      }

      this.isFormValid = true;
      this.errors[field] = null;
    },
    handleSubmit() {
      if (this.isCreating || !this.isFormValid) {
        return;
      }

      this.isCreating = true;
      this.$apollo
        .mutate({
          mutation: createArtGuideMutation,
          variables: {
            title: this.title,
            description: this.description,
          },
        })
        .then(({ data }) => {
          this.isCreating = false;
          this.finishCreate = true;
          this.title = null;
          this.description = null;
          this.isFormValid = false;
          this.$emit('created', data.createArtGuide);
        })
        .catch((e) => {
          const { graphQLErrors } = e;
          const errorMessage = graphQLErrors[0].message || 'Failed to create your Art Guide.';

          if (graphQLErrors[0].extensions?.validation) {
            this.isFormValid = false;
            const errors = graphQLErrors[0].extensions.validation;

            for (let field in errors) {
              if (this.errors[field] !== undefined) {
                this.errors[field] = errors[field][0];
              }
            }
          }

          this.isCreating = false;
          this.$toast.error(errorMessage);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-art-guide-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(#000, 0.5);

  .guide-modal-dialog {
    --app-agrc-modal-dialog-margin: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 740px;
    min-height: calc(100% - var(--app-agrc-modal-dialog-margin) * 2);
    margin: var(--app-agrc-modal-dialog-margin);
    pointer-events: none;

    @media (min-width: 800px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .guide-modal-content {
    $formGap: 24px;

    position: relative;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 64px 20px;
    background-color: #fff;
    pointer-events: auto;

    .guide-modal-close {
      position: absolute;
      top: 18px;
      right: 8px;
      z-index: 100;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      @media (min-width: 441px) {
        right: 18px;
      }

      @media (min-width: 577px) {
        top: 24px;
        right: 24px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .guide-form-section {
      max-width: 328px;
      width: 100%;
      margin: auto;

      &__header {
        margin-bottom: $formGap;
        text-align: center;
      }

      &__title {
        margin-bottom: 0;
        color: #363636;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 3px;
        text-transform: uppercase;
      }

      &__text {
        margin: 20px 0 0;
        text-align: center;
        font-size: 18px;
        color: #5b5d62;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      &__action {
        margin-top: 16px;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .guide-form {
      display: flex;
      flex-direction: column;
      gap: $formGap;
      width: 100%;

      &__btn-submit {
        padding: 12px 16px;
        border-radius: 1px;
        background-color: #363636;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;

        &:disabled {
          background-color: #a2a2a2;
        }

        &:hover,
        &:focus {
          color: #fff !important;
        }

        &:not([disabled]) {
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}
</style>
