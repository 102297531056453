<template>
  <div>
    <div v-if="entityDataFetching" class="current-item-wrapper">
      <div class="container blank text-center">
        <AppLoader />
      </div>
    </div>
    <div v-else-if="!entityDataFetching && !trip" class="current-item-wrapper">
      <div class="container">
        <MsgEntityNotFound name="Art Trip" />
      </div>
    </div>

    <template v-else-if="trip">
      <div class="current-item-wrapper">
        <EntityPageHeader
          :image="mediaUrl"
          :title="trip.title"
          title-before="International Art Trip"
          :title-after="tripDates"
        />
        <div class="container">
          <div class="data-details__actions">
            <LikeToAttendAction :event="trip" @show-museum-modal="isMuseumModal = true" />
            <AddToFavoriteAction :event="trip" :is-favorite="isFavoriteState" @click="handleFavoriteAction" />
          </div>
          <DataItemInfo :item="trip" />
        </div>
      </div>

      <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="trip.title" />
    </template>

    <UpgradeFromEnthusiastMsgModal v-model="isMuseumModal" />
  </div>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';
import { formatDate } from '@/helpers/dateTimeHelper';

import artTripQuery from '@/graphql/artTrip/ArtTrip.single.query.gql';
import addToFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import EntityPageHeader from '@/components/EntityPageHeader.vue';
import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction';
import LikeToAttendAction from '@/components/partials/actions/LikeToAttendAction';
import DataItemInfo from '@/components/partials/DataItemInfo';
import MapWithEntityDestination from '@/components/partials/MapWithEntityDestination';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';

export default {
  name: 'ArtTripSinglePage',
  components: {
    EntityPageHeader,
    MsgEntityNotFound,
    AddToFavoriteAction,
    LikeToAttendAction,
    DataItemInfo,
    MapWithEntityDestination,
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection'),
    UpgradeFromEnthusiastMsgModal: () => import('@/components/modals/UpgradeFromEnthusiastMsgModal'),
  },
  metaInfo() {
    return {
      title: this.trip?.title || 'Art Trip',
    };
  },
  data() {
    return {
      trip: null,
      entityDataFetching: true,
      isFavoriteState: false,
      isMuseumModal: false,
      relatedRecords: [],
    };
  },
  computed: {
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.trip);
    },
    tripDates() {
      const startDate = formatDate(this.trip.date?.[0].start_date);
      const endDate = formatDate(this.trip.date?.[0].end_date);

      let dates = startDate;
      if (startDate !== endDate) {
        dates += ` - ${endDate}`;
      }

      return dates;
    },
  },
  watch: {
    $route() {
      this.fetchEntityData();
    },
  },
  created() {
    this.fetchEntityData();
  },
  methods: {
    fetchEntityData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.entityDataFetching = true;

      this.$apollo
        .query({
          query: artTripQuery,
          variables,
        })
        .then(({ data }) => {
          const trip = data?.entity || null;

          if (variables.onlyId && trip?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, trip.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          this.entityDataFetching = false;
          this.trip = trip;

          if (this.trip) {
            this.isFavoriteState = this.trip.is_favorite_by_current_user;
            this.relatedRecords = this.trip.relatedRecords || [];
          }
        })
        .catch(() => {
          this.entityDataFetching = false;
        });
    },
    handleFavoriteAction() {
      if (!this.isFavoriteState) {
        this.$apollo
          .mutate({
            mutation: addToFavoritesMutation,
            variables: {
              entities: [{ id: this.trip.id, type: this.trip.__typename }],
            },
          })
          .then(({ data }) => {
            this.isFavoriteState = data.addToUserFavorites;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: this.trip.id, type: this.trip.__typename }],
          },
        })
        .then(({ data }) => {
          this.isFavoriteState = !data.deleteFromUserFavorites;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-item-wrapper {
  margin-top: 100px;
}

.blank {
  padding-top: 50px;
}
</style>
