<template>
  <button v-on="$listeners" class="action" type="button">
    <AppDownloadIcon />
    <span v-if="label" class="action__label">{{ label }}</span>
  </button>
</template>

<script>
import AppDownloadIcon from '@/components/icons/AppDownloadIcon.vue';

export default {
  name: 'AppDownloadAction',
  components: { AppDownloadIcon },
  props: {
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }

  &__label {
    color: #363636;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
}
</style>
