































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import InboxMsgIcon from '@/components/icons/InboxMsgIcon.vue';
import AppDownloadIcon from '@/components/icons/AppDownloadIcon.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheFilterIcon from '@/components/icons/TheFilterIcon.vue';

@Component({
  components: { TheFilterIcon, TheArrowHorizIcon, AppDownloadIcon, InboxMsgIcon },
  computed: {
    ...mapState(['unreadAppMessagesCount', 'isMobileScreenExclude768']),
    ...mapGetters(['isUserMembershipLapsed']),
  },
})
export default class BackHeader extends Vue {
  @Prop() title!: string;
  @Prop() description!: string;
  @Prop(Boolean) isStatic!: boolean;
  @Prop(Boolean) closeModal!: boolean;
  @Prop({ default: false, type: Boolean }) isShowTitle!: boolean;
  @Prop(Boolean) destination!: boolean;
  @Prop() backHandler!: any;
  @Prop({ default: '' }) withFilterFor!: string;
  @Prop({ default: 0 }) messages!: number;
  @Prop({ default: false, type: Boolean }) withPdf!: boolean;
  @Prop({ default: false, type: Boolean }) withFilter!: boolean;
  @Prop({ default: '' }) withInfo!: string;
  @Prop({ default: false, type: Boolean }) isDesktop!: boolean;
  @Prop({ default: false, type: Boolean }) skipDestinationQuery!: boolean;
  @Prop({ default: false, type: Boolean }) initShow!: boolean;

  isMobileScreenExclude768: boolean;
  isUserMembershipLapsed: boolean;

  get showHeader() {
    if (this.initShow) {
      return true;
    }

    const route = this.$route.name;

    if (this.isMobileScreenExclude768) {
      return route !== 'art-space' && route !== 'event' && route !== 'art-fair' && route !== 'art-trip';
    }
    return this.isDesktop;
  }

  goBack() {
    if (this.backHandler) {
      this.backHandler();
    } else {
      this.$router.go(-1);
    }
  }

  closeModalProfile() {
    this.$root.$emit('closeModalProfile');
  }

  showMsgInbox() {
    this.$store.dispatch('showMsgInbox', true);
  }

  openPDF() {
    if (this.isUserMembershipLapsed) {
      this.$store.dispatch('showOrHideRenewMembershipModalShown', true);
    } else {
      this.$root.$emit('openPDF');
    }
  }

  openFilter() {
    this.$store.dispatch('toggleAppDataFilter', true);
  }
}
