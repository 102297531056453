<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M30.4844 19.6194L20 31L9.51559 19.6194C7.42301 17.3479 7.50724 13.8551 9.70693 11.6845C12.163 9.2608 16.2087 9.48191 18.3786 12.1584L20 14.1584L21.6214 12.1584C23.7913 9.48191 27.837 9.2608 30.2931 11.6845C32.4928 13.8551 32.577 17.3479 30.4844 19.6194Z"
      :fill="fillColor"
      :stroke="strokeColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheHeartIcon',
  props: {
    filled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fillColor() {
      return this.filled ? '#363636' : '#ffffff';
    },
    strokeColor() {
      return this.filled ? '#363636' : '#363636';
    },
  },
};
</script>
