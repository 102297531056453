<template>
  <section class="art-guide-events-group-item-notes">
    <label class="art-guide-events-group-item-notes__label">
      <span class="art-guide-events-group-item-notes__label-text">My Notes</span>
      <textarea
        v-model="vModel"
        class="art-guide-events-group-item-notes__input"
        :maxlength="500"
        placeholder="Type something..."
        rows="2"
        @change="handleChange"
        @keydown.prevent.enter="handleChange"
      />
    </label>
    <div v-show="changing" class="art-guide-events-group-item-notes__loader">
      <AppLoader :border-width="2" :size="24" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    changing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item-notes {
  $borderColor: #7b61ff;

  position: relative;
  overflow: hidden;
  padding-top: 13px;
  padding-bottom: 3px;
  border: 1px solid $borderColor;

  &__label {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }

  &__label-text {
    display: block;
    margin-bottom: 4px;
    padding: 0 13px;
    font-size: 14px;
    font-weight: 600;

    @media (min-width: 577px) {
      padding: 0 15px;
      font-size: 16px;
    }
  }

  &__input {
    display: block;
    resize: vertical;
    width: 100%;
    min-height: 70px;
    padding: 0 13px 13px;
    font-size: 14px;
    font-weight: 400;
    border: none;

    @media (min-width: 577px) {
      padding: 0 15px 15px;
      font-size: 16px;
    }

    &::placeholder {
      color: #a2a2a2;
    }
  }

  &__loader {
    position: absolute;
    bottom: 8px;
    right: 28px;

    ::v-deep .app-loader {
      div {
        border-color: $borderColor transparent transparent transparent;
      }
    }
  }
}
</style>
