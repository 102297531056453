<template>
  <Transition name="fade">
    <div v-if="!activationEmailSend" key="common" class="common">
      <form @submit.prevent="validate">
        <div class="common-wrapper flex-grow-1">
          <div v-if="isMobileScreenExclude768" class="common-row">
            <div class="common-header clt-container">
              <div class="common-header__title">
                <RouterLink class="back-btn" :to="{ name: 'login' }" />
                <span class="title-text">Activate your account</span>
              </div>
            </div>
          </div>

          <div class="common-row">
            <div class="clt-container pb-4">
              <div class="common-text--big">
                <template v-if="isMobileScreenExclude768">To activate account:</template>
                <p v-else class="common-text--desktop">
                  To activate your account, please enter the email address you registered with below.
                </p>
              </div>
              <div class="common-text--small">Enter your email below to receive your password instructions.</div>
            </div>
          </div>

          <div class="common-row">
            <div class="clt-container">
              <div class="common-group">
                <label for="activate-email">Email Address</label>
                <input
                  id="activate-email"
                  v-model.trim="email"
                  type="email"
                  name="email"
                  autocomplete="username"
                  class="font-style"
                  :class="{ 'is-invalid': $v.email.$error }"
                  @input="emailError = ''"
                  @blur="$v.email.$touch()"
                />
              </div>
              <div v-if="$v.email.$error" class="form-dark-bg-error">
                <small v-if="!$v.email.required && $v.email.$dirty" class="form-dark-bg-error__msg"
                  >Email field is required.</small
                >
                <small v-else-if="!$v.email.email" class="form-dark-bg-error__msg">Please provide a valid email.</small>
                <small v-else-if="!$v.email.serverFailed" class="form-dark-bg-error__msg">{{ emailError }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="common-wrapper">
          <div class="clt-container text-center py-4">
            <button
              class="common-button"
              :class="{ 'clt-disabled': $v.$invalid }"
              :disabled="$v.$invalid"
              type="submit"
            >
              Continue
            </button>
          </div>
        </div>

        <div class="common-wrapper flex-grow-1">
          <div v-if="errorNotes" class="common-row">
            <div class="clt-container pt-4">
              <div class="common-text--big error-notes">
                <p>{{ errorNotes }}</p>
                <div class="office-contacts">
                  <p v-for="oc in officeContacts" :key="`oc-${oc.destination}`">
                    {{ oc.destination }}: <a :href="`tel:${oc.phone_raw}`">{{ oc.phone }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <AppLegalInfoLink class="common-legal-link" />
    </div>

    <div v-else key="email-send" class="activation-email-send">
      <span class="activation-email-send__msg"
        >A link to activate your account has just been sent to your email address. It may take a few moments to come
        through.</span
      >
    </div>
  </Transition>
</template>

<script>
import { mapState } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';
import apiRequest from '@/api/apiRequest';

import AppLegalInfoLink from '@/components/partials/AppLegalInfoLink';

export default {
  name: 'ActivateAccountPage',
  components: { AppLegalInfoLink },
  metaInfo: {
    title: 'Activate account',
  },
  data() {
    return {
      email: '',
      emailError: '',
      errorNotes: '',
      officeContacts: [],
      formSubmitted: false,
      activationEmailSend: false,
    };
  },
  computed: {
    ...mapState(['isMobileScreenExclude768']),
  },
  validations: {
    email: {
      required,
      email,
      serverFailed() {
        return !this.emailError;
      },
    },
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.sendActivateRequest();
    },
    sendActivateRequest() {
      if (this.formSubmitted) {
        return;
      }
      this.formSubmitted = true;
      this.emailError = '';
      this.errorNotes = '';

      apiRequest({
        url: 'activate/send-email',
        method: 'post',
        data: { email: this.email.toLowerCase() },
      })
        .then(() => {
          this.email = '';
          this.$v.$reset();
          this.activationEmailSend = true;
        })
        .catch(({ response }) => {
          const { status, data: err } = response;
          let error = 'Unfortunately, email has not been sent. Please contact with the administrator.';

          if (status === 422 && err.errors) {
            error = err.errors.email?.[0] || 'Email validation error.';
            this.emailError = error;
          } else if (err.message) {
            error = err.message;
            this.errorNotes =
              err.error_notes ||
              'Something went wrong. Please try again or call our membership team who will be able to help you.';
            this.officeContacts = err.office_contacts || [];
          }

          this.$toast.error(error);
        })
        .finally(() => {
          this.formSubmitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.common-text--desktop {
  margin-bottom: 0;
  padding: 0 45px;
}
.error-notes {
  text-align: left;
  text-transform: initial;

  p:last-child {
    margin-bottom: 0;
  }
  a {
    color: inherit;
  }
}
.activation-email-send {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  &__msg {
    display: block;
    max-width: 400px;
    padding: 0 15px;
    color: #363636;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
  }
}
.office-contacts {
  p {
    margin-bottom: 0;
  }
  a {
    margin-left: 10px;
  }
}
</style>
