<template>
  <article>
    <div v-if="entityDataFetching" class="current-item-wrapper">
      <div class="container blank text-center">
        <AppLoader />
      </div>
    </div>
    <div v-else-if="!entityDataFetching && !item" class="current-item-wrapper">
      <div class="container">
        <MsgEntityNotFound name="Academy" />
      </div>
    </div>

    <template v-else-if="item">
      <div class="current-item-wrapper">
        <EntityPageHeader :image="mediaUrl" :title="item.title" title-before="Cultivist" />
        <div class="container">
          <div class="data-details__actions">
            <LikeToAttendAction v-if="item.based_type === 'Scheduled'" :event="item" />
            <TicketRequestAction
              v-else-if="item.based_type !== 'Scheduled'"
              :event="item"
              object="academy"
              :object-id="item.id"
            />
            <AddToFavoriteAction :event="item" :is-favorite="isFavoriteState" @click="handleFavoriteAction" />
            <AddReviewAction />
          </div>
          <DataItemInfo :item="item" />
        </div>
      </div>

      <AppRelatedSection v-if="relatedRecords.length" :items="relatedRecords" :related-to-title="item.title" />
    </template>

    <UpgradeFromEnthusiastMsgModal v-model="isMuseumModal" />
  </article>
</template>

<script>
import ImageHandler from '@/helpers/ImageHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import academyQuery from '@/graphql/academy/Academy.single.query.gql';
import addToFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import EntityPageHeader from '@/components/EntityPageHeader.vue';
import DataItemInfo from '@/components/partials/DataItemInfo';
import AddToFavoriteAction from '@/components/partials/actions/AddToFavoriteAction';
import AddReviewAction from '@/components/partials/actions/AddReviewAction.vue';

export default {
  name: 'AcademySinglePage',
  components: {
    MsgEntityNotFound,
    EntityPageHeader,
    DataItemInfo,
    AddToFavoriteAction,
    AddReviewAction,
    LikeToAttendAction: () => import('@/components/partials/actions/LikeToAttendAction'),
    TicketRequestAction: () => import('@/components/partials/actions/TicketRequestAction'),
    AppRelatedSection: () => import('@/components/partials/AppRelatedSection'),
    UpgradeFromEnthusiastMsgModal: () => import('@/components/modals/UpgradeFromEnthusiastMsgModal'),
  },
  metaInfo() {
    return {
      title: this.item?.title || 'Academy',
    };
  },
  data() {
    return {
      item: null,
      entityDataFetching: true,
      isFavoriteState: false,
      isMuseumModal: false,
      relatedRecords: [],
    };
  },
  computed: {
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
  },
  watch: {
    $route() {
      this.fetchEntityData();
    },
  },
  created() {
    this.fetchEntityData();
  },
  methods: {
    fetchEntityData() {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.entityDataFetching = true;

      this.$apollo
        .query({
          query: academyQuery,
          variables,
        })
        .then(({ data }) => {
          const item = data?.entity || null;

          if (variables.onlyId && item?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, item.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          this.entityDataFetching = false;
          this.item = item;

          if (!this.item) {
            return;
          }

          this.isFavoriteState = this.item.is_favorite_by_current_user;
          this.relatedRecords = this.item.relatedRecords || [];

          this.item.date = null;
          if (this.item.start_date && this.item.end_date) {
            this.item.date = [
              {
                start_date: this.item.start_date,
                end_date: this.item.end_date,
              },
            ];
          }
        })
        .catch(() => {
          this.entityDataFetching = false;
        });
    },
    handleFavoriteAction() {
      if (!this.isFavoriteState) {
        this.$apollo
          .mutate({
            mutation: addToFavoritesMutation,
            variables: {
              entities: [{ id: this.item.id, type: this.item.__typename }],
            },
          })
          .then(({ data }) => {
            this.isFavoriteState = data.addToUserFavorites;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ id: this.item.id, type: this.item.__typename }],
          },
        })
        .then(({ data }) => {
          this.isFavoriteState = !data.deleteFromUserFavorites;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-item-wrapper {
  margin-top: 100px;
}

.blank {
  padding-top: 50px;
}
</style>
