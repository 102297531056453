<template>
  <div class="art-guide-events-group-item-actions">
    <div class="art-guide-events-group-item-actions__action">
      <button v-if="!changingFavorite" :disabled="actionButtonDisabled" @click="$emit('changing-favorite')">
        <TheHeartIcon :filled="isFavorite" />
      </button>
      <AppLoader v-else v-bind="loaderOptions" class="art-guide-events-group-item-actions__loader" />
    </div>

    <div class="art-guide-events-group-item-actions__action">
      <button v-if="!isDetaching" @click="$emit('detaching-event')">
        <AppTrashIcon />
      </button>
      <AppLoader v-else v-bind="loaderOptions" class="art-guide-events-group-item-actions__loader" />
    </div>
  </div>
</template>

<script>
import TheHeartIcon from '@/components/icons/TheHeartIcon.vue';
import AppTrashIcon from '@/components/icons/AppTrashIcon.vue';

export default {
  components: { TheHeartIcon, AppTrashIcon },
  props: {
    changingFavorite: {
      type: Boolean,
      default: false,
    },
    isDetaching: {
      type: Boolean,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaderOptions: {
        borderWidth: 3,
        margin: 0,
        size: 20,
      },
    };
  },
  computed: {
    actionButtonDisabled() {
      return this.changingFavorite || this.isDetaching;
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item-actions {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 577px) {
    top: 16px;
    right: 16px;
    gap: 12px;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 4px;
    background-color: #fff;
    border-radius: 50%;

    @media (min-width: 577px) {
      width: 40px;
      height: 40px;
    }

    button {
      padding: 0;
    }

    svg {
      width: 100%;
    }
  }

  &__loader {
    --app-art-guide-event-action-loader-size: 14px;

    @media (min-width: 577px) {
      --app-art-guide-event-action-loader-size: 20px;
    }

    width: var(--app-art-guide-event-action-loader-size);
    height: var(--app-art-guide-event-action-loader-size);

    ::v-deep {
      div {
        width: var(--app-art-guide-event-action-loader-size);
        height: var(--app-art-guide-event-action-loader-size);
        border-width: 2px;

        @media (min-width: 577px) {
          border-width: 3px;
        }
      }
    }
  }
}
</style>
