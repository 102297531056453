<template>
  <label class="create-art-guide-form-input">
    <span class="input-label-text" :class="{ 'input-label-text--required': required }">{{ label }}</span>
    <input
      v-model.trim="vModel"
      class="input-element"
      :maxlength="maxlength"
      :minlength="minlength"
      :placeholder="placeholder"
      :required="required"
      type="text"
    />
    <Transition name="fade-down">
      <span v-show="error" class="input-error">{{ error }}</span>
    </Transition>
  </label>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    maxlength: {
      type: Number,
      default: 255,
    },
    minlength: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.create-art-guide-form-input {
  display: block;
  margin-bottom: 0;

  .input-label-text {
    display: block;
    color: #5b5d62;
    font-size: 16px;
    line-height: 24px;

    &--required::after {
      content: '*';
      margin-left: 1px;
    }
  }

  .input-element {
    width: 100%;
    padding: 12px 16px;
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    border-radius: 1px;
    border: 1px solid #a2a2a2;
    background-color: #fff;

    &::placeholder {
      color: #a2a2a2;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  .input-error {
    display: block;
    margin-top: 4px;
    font-size: 14px;
    line-height: normal;
    color: #dc3545;
    letter-spacing: 1px;
  }
}
</style>
