<template>
  <div class="art-guide-events-group-item-opening-hour-seasons">
    <section v-for="(season, sIdx) in seasons" :key="`oh-season-${sIdx}`" class="opening-hours-season">
      <header v-show="season.title">
        <h4 class="opening-hours-season__title">{{ season.title }}:</h4>
      </header>
      <ul class="opening-hours-season__items">
        <li v-for="(ohItem, ohIdx) in season.items" :key="`oh-season-${sIdx}-${ohIdx}`">
          <ArtGuideEventsGroupItemOpeningHourSeasonItem :item="ohItem" />
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import ArtGuideEventsGroupItemOpeningHourSeasonItem from '@/components/artguide/ArtGuideEventsGroupItemOpeningHourSeasonItem.vue';

export default {
  components: { ArtGuideEventsGroupItemOpeningHourSeasonItem },
  props: {
    seasons: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item-opening-hour-seasons {
  .opening-hours-season {
    &:not(:first-child) {
      margin-top: 8px;
    }

    &:not(:last-child) {
      padding-bottom: 8px;
      border-bottom: 1px solid #dadcde;
    }

    &__title {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;

      @media (min-width: 577px) {
        font-size: 16px;
      }
    }

    &__items {
      list-style-type: none;
    }
  }
}
</style>
