<template>
  <div class="app-collapsible-content-section">
    <div
      ref="content"
      class="content"
      :class="{ 'content--full': show || !isContentHeightGreaterThanMaxValue }"
      :style="{ maxHeight: maxHeightValue }"
    >
      <slot />
    </div>
    <button v-if="isContentHeightGreaterThanMaxValue" type="button" class="action-expand" @click="show = !show">
      <span v-if="!show">Read more</span>
      <span v-else>Read less</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    maxHeight: {
      type: Number,
      default: 150,
    },
  },
  data() {
    return {
      isContentHeightGreaterThanMaxValue: false,
      show: false,
    };
  },
  mounted() {
    this.checkContentHeight();
  },
  computed: {
    maxHeightValue() {
      return this.show || !this.isContentHeightGreaterThanMaxValue ? 'none' : `${this.maxHeight}px`;
    },
  },
  methods: {
    checkContentHeight() {
      const contentElement = this.$refs.content;
      if (contentElement) {
        this.isContentHeightGreaterThanMaxValue = contentElement.scrollHeight > this.maxHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-collapsible-content-section {
  .content {
    position: relative;
    overflow: hidden;

    &:not(&--full)::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40%;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.45), #fff);
      transition: all 0.5s ease-in-out;
    }
  }

  .action-expand {
    margin-top: 8px;
    padding: 0;
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
    color: #363636;

    @media (min-width: 577px) {
      font-size: 16px;
    }
  }
}
</style>
