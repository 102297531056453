<template>
  <div class="select-wrapper">
    <PaymentMethodFormLabel :for="id" :label="selectLabel" move-to-up />
    <Multiselect
      :id="id"
      v-bind="$attrs"
      :allow-empty="false"
      :options="options"
      :preselect-first="false"
      :preserve-search="true"
      :show-labels="false"
      :value="value"
      @select="onSelect"
    />
    <PaymentMethodFormError :error="error" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import PaymentMethodFormError from '@/components/partials/profile/PaymentMethodFormError';
import PaymentMethodFormLabel from '@/components/partials/profile/PaymentMethodFormLabel';

export default {
  name: 'PaymentMethodFormSelect',
  components: {
    Multiselect,
    PaymentMethodFormError,
    PaymentMethodFormLabel,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Object],
      default: null,
    },
  },
  methods: {
    onSelect(option) {
      this.$emit('input', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-wrapper {
  position: relative;
  margin-top: 30px;

  &::v-deep .multiselect {
    color: #363636;

    .multiselect__tags {
      padding: 10px 60px 0 1px;
      border: none;
      border-bottom: 1px solid rgba(53, 53, 53, 0.1);
      border-radius: 0;
    }

    .multiselect__placeholder,
    .multiselect__input::placeholder,
    .multiselect__single {
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 2px;
    }

    .multiselect__placeholder,
    .multiselect__input::placeholder {
      font-weight: 400;
      color: #acacac;
    }

    .multiselect__input {
      padding-left: 1px;
      padding-top: 1px;
      margin-bottom: 13px;
      font-weight: 400;
      font-size: 20px;
      line-height: inherit;

      &:focus {
        outline: none;
      }
    }

    .multiselect__placeholder {
      margin-bottom: 13px;
      padding-top: 1px;
      padding-left: 1px;
    }

    .multiselect__select {
      width: 40px;
      height: 100%;
    }
    .multiselect__select::before {
      width: 8px;
      height: 8px;
      position: absolute;
      right: 16px;
      top: 46%;
      margin-top: 0;
      border-color: #363636;
      border-style: solid;
      border-width: 2px 2px 0 0;
      transform: translateY(-50%) rotate(135deg);
      transition: transform 0.2s ease;
    }
    &--active .multiselect__select {
      transform: none;
    }
    &--active .multiselect__select::before {
      top: 52%;
      transform: translateY(-50%) rotate(315deg);
    }

    .multiselect__single {
      min-height: 30px;
      line-height: inherit;
      margin-bottom: 13px;
      padding-top: 1px;
      padding-left: 0;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
    }

    .multiselect__option {
      padding-left: 20px;
      letter-spacing: 1px;
      white-space: normal;
    }
    .multiselect__option--selected {
      background: #363636;
      color: #ffffff;
      font-weight: 400;
    }
    .multiselect__option--highlight:not(.multiselect__option--selected) {
      background: #f3f3f3;
      color: #363636;
    }
    .multiselect__option--highlight.multiselect__option--selected {
      cursor: default;
    }

    .multiselect__option--group {
      padding-left: 15px;
      font-weight: 400;
      color: #acacac !important;
    }
  }
}
</style>
