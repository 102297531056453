



























































import { Component, Prop, Vue } from 'vue-property-decorator';

import artGuidesDataQuery from '@/graphql/artGuide/ArtGuides.commonData.query.gql';
import attachEventToArtGuideMutation from '@/graphql/artGuide/AttachEventToArtGuide.mutation.gql';

import CreateArtGuideModal from '@/components/artguide/CreateArtGuideModal.vue';
import ThePlusIcon from '@/components/icons/ThePlusIcon.vue';
import TheMapWithMarkerIcon from '@/components/icons/TheMapWithMarkerIcon.vue';

const PER_PAGE = 9;

@Component({
  components: {
    TheMapWithMarkerIcon,
    ThePlusIcon,
    CreateArtGuideModal,
  },
  apollo: {
    artGuidesData: {
      query: artGuidesDataQuery,
      variables() {
        return {
          excludedEvents: this.eventIdentifier.id ? [this.eventIdentifier] : [],
          limit: PER_PAGE,
          nextCursor: this.nextCursor,
        };
      },
      result(result: any) {
        this.artGuides.push(...result.data.artGuidesData.data);

        this.$nextTick(() => {
          const loadState = this.$refs.infiniteLoad?.stateChanger;
          if (!loadState) {
            return;
          }

          if (this.artGuidesData.hasMorePages) {
            loadState.loaded();
          } else {
            loadState.complete();
          }
        });
      },
    },
  },
})
export default class AddToArtGuideCnt extends Vue {
  @Prop() event!: any;

  public artGuidesData: any;
  public artGuides: any = [];
  public nextCursor: string | null = null;

  public isShowModal = false;
  public attachingGuideId: number | null = null;
  public alreadyAttached = false;

  get fetchingArtGuidesList() {
    return this.$apollo.queries.artGuidesData.loading;
  }

  get showListScroll() {
    return this.artGuides.length >= PER_PAGE;
  }

  get eventIdentifier() {
    if (!this.event) {
      return { id: null, type: null };
    }
    return { id: +this.event.id, type: this.event.__typename };
  }

  get hasMoreData(): boolean {
    return this.artGuidesData && this.artGuidesData.hasMorePages;
  }

  loadMore(state: any) {
    if (this.hasMoreData) {
      this.nextCursor = this.artGuidesData.nextCursor;
      return;
    }
    state.complete();
  }

  toggleCreateModal() {
    this.isShowModal = !this.isShowModal;
  }

  addToGuide(artGuideId: number) {
    if (this.alreadyAttached || this.attachingGuideId !== null) {
      return;
    }
    this.attachingGuideId = artGuideId;

    this.$apollo
      .mutate({
        mutation: attachEventToArtGuideMutation,
        variables: {
          art_guide_id: artGuideId,
          event_id: this.eventIdentifier.id,
          event_type: this.eventIdentifier.type,
        },
      })
      .then(({ data }: any) => {
        if (!data.attachEventToArtGuide) {
          this.$toast.error('Failed to add the item to this Art Guide.');
          return;
        }

        this.alreadyAttached = true;
        this.artGuides.find((guide: any) => guide.id === artGuideId).count_events++;

        this.$emit('added-to-guide');
      })
      .catch(() => {
        this.$toast.error('Failed to add the item to this Art Guide.');
      })
      .finally(() => {
        this.attachingGuideId = null;
      });
  }

  afterArtGuideCreated(data: any) {
    this.isShowModal = false;
    if (data) {
      this.artGuides.unshift(data);
    }
  }
}
