























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import uniqueId from '@/helpers/uniqueId';

@Component
export default class AppFormInput extends Vue {
  @Prop() label!: string;
  @Prop() value!: string | number;
  @Prop({ default: '' }) error: string;
  @Prop({ default: 'text' }) type: string;
  @Prop({ default: 255 }) maxlength: number;

  active = false;
  focus = false;

  get id() {
    return 'app-form-input-' + uniqueId();
  }

  get inputListeners() {
    return {
      ...this.$listeners,
      input: (event: any) => {
        this.$emit('input', event.target.value);
      },
      change: (event: any) => {
        const value = event.target.value.trim();
        this.$emit('input', value);
        this.$emit('change', value);
      },
    };
  }

  @Watch('value')
  watchValue(newValue: string) {
    if (!newValue && !this.focus) {
      this.active = false;
      this.focus = false;
    }
  }

  created() {
    this.active = !!this.value;
  }

  onFocus() {
    this.active = true;
    this.focus = true;
  }

  onBlur() {
    if (!this.value) {
      this.active = false;
    }
    this.focus = false;
  }
}
