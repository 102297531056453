<template>
  <div class="opening-hour-season-item">
    <span class="opening-hour-season-item__days">{{ shortDays }}:</span>
    <ul class="opening-hour-season-item__hours">
      <li v-for="(h, hIdx) in item.hours" :key="`${item.days}_${hIdx}`">{{ h }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shortDays() {
      return this.item.days
        .split(' - ')
        .map((d) => d.substring(0, 3))
        .join(' - ');
    },
  },
};
</script>

<style lang="scss" scoped>
.opening-hour-season-item {
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  text-transform: uppercase;

  &__days {
    width: 75px;

    @media (min-width: 577px) {
      width: 85px;
    }
  }

  &__hours {
    list-style-type: none;
  }
}
</style>
