<template>
  <ArtGuidePageSection class="art-guide-introductory-section">
    <p class="message-from-team">{{ artGuide.message_from_team }}</p>

    <section class="emergency-contact" v-if="artGuide.emergency_contact">
      <header class="emergency-contact__header">
        <h2 class="emergency-contact__title">Emergency contact</h2>
      </header>
      <div class="emergency-contact__info">
        <p>{{ artGuide.emergency_contact.name }}</p>
        <p v-show="artGuide.emergency_contact.phone">
          <a :href="`tel:${artGuide.emergency_contact.phone_formatted}`">{{ artGuide.emergency_contact.phone }}</a>
        </p>
        <p v-show="artGuide.emergency_contact.email">
          <a :href="`mailto:${artGuide.emergency_contact.email}`">{{ artGuide.emergency_contact.email }}</a>
        </p>
      </div>
    </section>
  </ArtGuidePageSection>
</template>

<script>
import ArtGuidePageSection from '@/components/artguide/ArtGuidePageSection.vue';

export default {
  components: { ArtGuidePageSection },
  props: {
    artGuide: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-introductory-section {
  .message-from-team {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }

  .emergency-contact {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;

    @media (min-width: 577px) {
      gap: 40px;
    }

    &__header {
      padding: 12px 16px;
      text-align: center;
      border: 1px solid #dadcde;
      background-color: #fff;
    }

    &__title {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
    }

    &__info {
      flex: 1;
      font-size: 16px;
      line-height: 24px;

      & p {
        margin: 0;

        & a {
          color: #363636;
          overflow-wrap: break-word;
        }
      }
    }
  }
}
</style>
