import apiProtectedRequest from '@/api/apiProtectedRequest';
import { AxiosError, AxiosResponse } from 'axios';

export async function storeOrderOfArtGuideEvents(id: number, payload: Array<number | string>) {
  return new Promise<AxiosResponse>((resolve, reject) => {
    apiProtectedRequest
      .post(`art-guides/${id}/events-order`, { ordered_events: payload })
      .then((res: AxiosResponse) => {
        resolve(res.data);
      })
      .catch(async (err: AxiosError) => {
        reject(err.response?.data || { message: 'Something went wrong.' });
      });
  });
}
