<template>
  <div class="container" :class="{ 'container--with-mb': whatsHappeningData.length > 3 }">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center justify-content-between pad-bottom" data-aos="fade-up">
        <div class="title">{{ title }}</div>
        <div class="see-all">
          <RouterLink :to="routeToAll">See all</RouterLink>
        </div>
      </div>

      <div v-if="whatsHappeningData.length <= 3" class="row">
        <div
          v-for="(item, i) in whatsHappeningData"
          :key="`${routeAllName}_${item.id}_${i}`"
          class="col-sm-6 col-lg-4"
          data-aos="fade-up"
        >
          <DestinationWhatsHappeningItem :item="item" />
        </div>
      </div>
      <div v-else class="row">
        <AppSwiper
          :data="whatsHappeningData"
          :data-type="type"
          :disable-padding-x="false"
          :has-more-data="hasMoreData"
          :route="routeName"
          :title-event="title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { makeRouteQueryFromData } from '@/helpers/routerHelper';
import { FILTER_TYPE_DESTINATIONS } from '@/helpers/data-filter/DataFilterTypes';

import AppSwiper from '@/components/partials/elements/AppSwiper';
import DataItemDates from '@/components/partials/DataItemDates';
import DestinationWhatsHappeningItem from '@/components/destination/DestinationWhatsHappeningItem';

export default {
  components: { DestinationWhatsHappeningItem, DataItemDates, AppSwiper },
  props: {
    destination: {
      type: Object,
      required: true,
    },
    hasMoreData: {
      type: Boolean,
      default: false,
    },
    isMobileScreen: {
      type: Boolean,
      default: false,
    },
    routeAllName: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    whatsHappeningData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    routeToAll() {
      const query = makeRouteQueryFromData({
        [FILTER_TYPE_DESTINATIONS]: [this.destination.slug],
      });
      return { name: this.routeAllName, query };
    },
  },
};
</script>

<style lang="scss" scoped>
.container--with-mb {
  margin-bottom: 50px;

  @media (min-width: 768px) {
    margin-bottom: 80px;
  }
}

.pad-bottom {
  padding-bottom: 30px;

  @media (min-width: 768px) {
    padding-bottom: 50px;
  }
}

.title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #363636;

  @media (min-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 6px;
  }

  @media (min-width: 992px) {
    font-size: 26px;
    letter-spacing: 8.33px;
  }
}

.see-all {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.11px;
  text-transform: uppercase;
  color: #363636;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
}
</style>
