<template>
  <button class="art-guide-events-group-reorder-action" type="button" title="Reorder" v-on="$listeners">
    <ArtGuideEventDragIcon />
  </button>
</template>

<script>
import ArtGuideEventDragIcon from '@/components/artguide/ArtGuideEventDragIcon.vue';

export default {
  components: { ArtGuideEventDragIcon },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-reorder-action {
  width: 24px;
  height: 24px;
  padding: 0;
  background-color: #fff;

  @media (min-width: 577px) {
    width: 32px;
    height: 32px;
  }

  &:hover {
    ::v-deep svg {
      path {
        stroke: #363636;
      }
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
