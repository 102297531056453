export function datetimeToHuman(datetime = '', options: Intl.DateTimeFormatOptions | null = null) {
  if (!datetime) {
    return null;
  }

  if (!options) {
    options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    };
  }

  return new Date(datetime).toLocaleDateString('en-GB', options);
}

export function getMonthName(datetime: string | { year: number; month: number }, short = false): string | null {
  if (!datetime) {
    return null;
  }

  let dateInstance = new Date();

  if (typeof datetime === 'object' && datetime.year && datetime.month) {
    dateInstance = new Date(datetime.year, datetime.month - 1, 1);
  } else if (typeof datetime === 'string') {
    dateInstance = new Date(datetime);
  }

  const options: Intl.DateTimeFormatOptions = {
    month: short ? 'short' : 'long',
  };

  return dateInstance.toLocaleDateString('en-GB', options);
}

export function formatDateToISO(dateTimeValue: string | Date) {
  if (!dateTimeValue) {
    return '';
  }

  const dateTime = new Date(dateTimeValue);
  if (dateTime.toString() === 'Invalid Date') {
    return '';
  }

  const year = dateTime.getFullYear();
  let month = String(dateTime.getMonth() + 1);
  if (month.length < 2) {
    month = '0' + month;
  }
  let day = String(dateTime.getDate());
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function formatDate(
  dateTimeValue: string | Date,
  inputOptions: Intl.DateTimeFormatOptions | undefined = undefined
) {
  if (!dateTimeValue) {
    return '';
  }

  const dateTime = new Date(dateTimeValue);
  if (dateTime.toString() === 'Invalid Date') {
    return '';
  }

  const options = inputOptions || {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };

  return new Intl.DateTimeFormat('en-GB', options).format(dateTime);
}

export function formatTime(dateTimeValue: string) {
  if (!dateTimeValue) {
    return '';
  }

  if (/^\d{2}:\d{2}:\d{2}$/.test(dateTimeValue)) {
    dateTimeValue = new Date().toDateString() + ` ${dateTimeValue}`;
  }

  const dateTime = new Date(dateTimeValue);
  if (dateTime.toString() === 'Invalid Date') {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    hour12: true,
  };

  if (dateTime.getMinutes()) {
    options.minute = 'numeric';
  }

  return new Intl.DateTimeFormat('en-US', options).format(dateTime);
}

export function diffInDays(from: Date | string, to: Date | string) {
  const fromDate = from instanceof Date ? from : new Date(from);
  const toDate = to instanceof Date ? to : new Date(to);

  const diff = toDate.getTime() - fromDate.getTime();

  return Math.round(diff / (1000 * 3600 * 24));
}

export function durationInDays(from: Date | string, to: Date | string) {
  const diff = diffInDays(from, to);

  return diff > 0 ? diff + 1 : 1;
}
