<template>
  <section class="art-guide-events-group">
    <h2 class="art-guide-events-group__title">{{ group.name }}</h2>
    <div class="art-guide-events-group__list">
      <ArtGuidePageSection v-for="event in group.events" :key="event.id" class="art-guide-events-group__list-item">
        <ArtGuideEventsGroupItem
          :event="event"
          @favorite-changed="handleChangeEventFavorite"
          @notes-changed="handleChangeEventNotes"
        >
          <template #event-type-section-right-side>
            <ArtGuideEventsGroupReorderAction v-if="showReorderAction" @click="$emit('reorder', group.type)" />
          </template>
        </ArtGuideEventsGroupItem>
      </ArtGuidePageSection>
    </div>
  </section>
</template>

<script>
import ArtGuidePageSection from '@/components/artguide/ArtGuidePageSection.vue';
import ArtGuideEventsGroupItem from '@/components/artguide/ArtGuideEventsGroupItem';
import ArtGuideEventsGroupReorderAction from '@/components/artguide/ArtGuideEventsGroupReorderAction.vue';

export default {
  components: { ArtGuidePageSection, ArtGuideEventsGroupItem, ArtGuideEventsGroupReorderAction },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    showReorderAction() {
      return !this.group.type.startsWith('event_') && this.group.events.length > 1;
    },
  },
  methods: {
    handleChangeEventFavorite() {
      this.$emit('favorite-changed');
    },
    handleChangeEventNotes() {
      this.$emit('notes-changed');
    },
  },
};
</script>

<style lang="scss">
.art-guide-events-group {
  &__title {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 577px) {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.15em;
    }
  }

  &__list {
    &-item {
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
</style>
