<template>
  <AppRouteLink class="app-swiper-item" :route-to="routeTo">
    <div class="item-img-wrapper">
      <div class="item-img animation" :style="`background-image: url(${media})`" />

      <EntityCardLabel v-if="entityCardLabel" :label="entityCardLabel" />
      <StaffPickLabel v-if="item.staff_pick" :v-align="entityCardLabelForStaffPickHorizAlign" />
    </div>

    <div class="item-title">{{ item.title }}</div>
    <div v-if="item.address" class="small-grey">{{ item.address }}</div>
    <DataItemDates class="item-subcontent" :dates="dates" />

    <div v-if="item.__typename === 'MiniCultivistType' && item.type !== 'Event'" class="d-flex flex-column">
      <div class="small-grey">{{ item.type }}</div>
      <div class="small-grey mt-1">{{ publishDate }}</div>
    </div>
    <div v-else-if="item.__typename !== 'MiniCultivistType' && publishDate" class="small-grey">
      {{ publishDate }}
    </div>

    <div v-if="item.destination && !relatedMode" class="d-flex item-subcontent">
      <div v-if="item.destination.title" class="small-grey">{{ item.destination.title }},</div>
      <div v-if="item.destination.country" class="small-grey ml-1">{{ item.destination.country }}</div>
    </div>
  </AppRouteLink>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityCardLabel, getEntityCardLabelForStaffPickHorizAlign } from '@/helpers/entityCardLabel';
import { formatDate } from '@/helpers/dateTimeHelper';

import AppRouteLink from '@/components/partials/AppRouteLink';
import DataItemDates from '@/components/partials/DataItemDates';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import StaffPickLabel from '@/components/partials/StaffPickLabel.vue';

export default {
  name: 'AppSwiperItem',
  components: { StaffPickLabel, EntityCardLabel, AppRouteLink, DataItemDates },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    relatedMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dates() {
      if (this.item.date && this.item.date.length) {
        return this.item.date;
      }
      return [
        {
          start_date: this.item.start_date || null,
          end_date: this.item.end_date || null,
        },
      ];
    },
    entityCardLabel() {
      return getEntityCardLabel(this.item);
    },
    entityCardLabelForStaffPickHorizAlign() {
      return getEntityCardLabelForStaffPickHorizAlign(this.item);
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    publishDate() {
      return formatDate(this.item.startPublishingAt);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.animation {
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    transform: scale(0.95);
  }
}

.app-swiper-item {
  display: block;
}

.item-img {
  &-wrapper {
    position: relative;
  }

  width: 100%;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #d9d9d9;

  @media (max-width: 768px) {
    height: 171px;
  }
}

.item-title {
  display: block;
  margin: 10px 0;
  font-family: 'Futura PT', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.85px;
  text-transform: uppercase;
  color: #353535 !important;

  @media (min-width: 768px) {
    margin-top: 20px;
    font-size: 22px;
    line-height: 30px;
  }
}
.item-subcontent {
  .small-grey {
    font-size: 14px;
    line-height: 20px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
}
</style>
