<template>
  <div class="story-campaign-group-item">
    <AppRouteLink class="story-campaign-group-item__link" :route-to="routeTo">
      <div class="story-campaign-group-item__image-wrapper">
        <div class="story-campaign-group-item__image" :style="`background-image:url(${media})`" />

        <EntityCardLabel v-if="entityCardLabel" :label="entityCardLabel" />
        <StaffPickLabel v-if="item.staff_pick" :v-align="entityCardLabelForStaffPickHorizAlign" />
      </div>

      <h3 class="story-campaign-group-item__title">{{ item.title }}</h3>

      <div>
        <DataItemDates :dates="dates" />
        <div v-if="item.category" class="small-grey">{{ item.category }}</div>

        <div v-if="item.destination" class="d-flex">
          <div v-if="item.destination.title" class="small-grey">{{ item.destination.title }},</div>
          <div v-if="item.destination.country" class="small-grey ml-1">{{ item.destination.country }}</div>
        </div>
      </div>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityCardLabel, getEntityCardLabelForStaffPickHorizAlign } from '@/helpers/entityCardLabel';

import DataItemDates from '@/components/partials/DataItemDates';
import TheFavoritesIcon from '@/components/partials/TheFavoritesIcon';
import AppRouteLink from '@/components/partials/AppRouteLink';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import StaffPickLabel from '@/components/partials/StaffPickLabel.vue';

export default {
  name: 'StoryCampaignGroupItem',
  components: {
    StaffPickLabel,
    EntityCardLabel,
    AppRouteLink,
    TheFavoritesIcon,
    DataItemDates,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dates() {
      if (this.item.date && this.item.date.length) {
        return this.item.date;
      }
      return [
        {
          start_date: this.item.start_date || null,
          end_date: this.item.end_date || null,
        },
      ];
    },
    entityCardLabel() {
      return getEntityCardLabel(this.item);
    },
    entityCardLabelForStaffPickHorizAlign() {
      return getEntityCardLabelForStaffPickHorizAlign(this.item);
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.story-campaign-group-item {
  $item: &;

  position: relative;

  &__link {
    color: inherit;
  }

  &__image {
    &-wrapper {
      position: relative;
    }

    height: 220px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #d9d9d9;
    transition: all 0.3s linear;

    @media (min-width: 768px) {
      height: 225px;
    }
    @media (min-width: 992px) {
      height: 250px;
    }
  }
  a#{$item}__link:hover &__image {
    transform: scale(0.95);
  }

  &__title {
    margin: 20px 0 10px;
    font-family: 'Futura PT', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.85px;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 22px;
    }
  }
}
</style>
