<template>
  <div class="wrapper pad-bottom">
    <div
      v-if="agendaLastItems.length"
      class="data-page-top d-flex justify-content-between align-items-center"
      data-aos="fade-up"
    >
      <h2 class="page-title">My agenda</h2>
      <RouterLink class="see-all" :to="{ name: 'My Agenda' }">See all</RouterLink>
    </div>
    <div class="row">
      <div
        v-for="(item, i) in agendaLastItems"
        :key="`agenda-item-${i}`"
        class="col-lg-6 col-xl-4 pad-bottom"
        data-aos="fade-up"
      >
        <RouterLink
          class="agenda-item"
          :class="{ 'agenda-item--is-expired': item.isExpired }"
          :to="routeLink(item.object)"
        >
          <div class="data-item__img" :style="`background-image:url(${getMedia(item.object)})`" />
          <div v-if="item.object.title" class="data-item__title">{{ item.object.title }}</div>
          <div
            v-if="item.object.date && item.object.date[0] && item.object.date[0].start_date"
            class="d-flex align-items-center mt-1"
          >
            <div class="small-grey">{{ item.object.date[0].start_date | moment('DD MMM YYYY') }}</div>
            <div class="small-grey mx-2">-</div>
            <div class="small-grey">{{ item.object.date[0].end_date | moment('DD MMM YYYY') }}</div>
          </div>
          <div v-if="item.object.type" class="d-flex align-items-center mt-1">
            <div class="small-grey">{{ item.object.type }}</div>
          </div>
          <div v-if="item.object.category" class="d-flex align-items-center mt-1">
            <div class="small-grey">{{ item.object.category }}</div>
          </div>
          <div v-if="item.object.destination && item.object.destination.title" class="d-flex align-items-center">
            <div class="small-grey">{{ item.object.destination.title }},</div>
            <div v-show="item.object.destination.country" class="small-grey ml-1">
              {{ item.object.destination.country }}
            </div>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import agendaLastItemsQuery from '@/graphql/me/agenda/MyAgendaLastItems.query.gql';

export default {
  data() {
    return {
      agendaLastItems: [],
    };
  },
  apollo: {
    agendaLastItems: {
      fetchPolicy: 'no-cache',
      query: agendaLastItemsQuery,
      variables() {
        return {
          limit: 6,
        };
      },
    },
  },
  methods: {
    getMedia(object) {
      return new ImageHandler().getFirstImgFromMedia(object);
    },
    routeLink(object) {
      const route = getEntityRoute(object);
      return route ? route : '/';
    },
  },
};
</script>

<style scoped lang="scss">
.agenda-item {
  &:hover {
    .data-item__img {
      transform: scale(0.95);
    }
  }
}
.data-item__img {
  margin-bottom: 15px;
  transition: all 0.3s linear;
}

.data-item__title {
  color: #353535;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.85px;
  line-height: 30px;
  text-transform: uppercase;
  cursor: pointer;
}

.pad-bottom {
  padding-bottom: 40px;

  > .data-item__img {
    transition: all 0.2s linear;
    cursor: pointer;
  }

  &:hover {
    > .data-item__img {
      transform: scale(0.95);
    }
  }
}

.data-page-top {
  padding-bottom: 30px;
  margin-bottom: 0;
}

.page-title {
  color: #353535;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.86px;
  margin-bottom: 0;
}

.see-all {
  color: #363636;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.11px;
  text-decoration: underline;
  text-transform: uppercase;
}
</style>
