<template>
  <Transition name="fade" @after-leave="$emit('close')" @enter="handleTransitionEnter">
    <div v-if="value" class="art-guide-events-reorder-control" @click.self="handleClose">
      <div class="app-modal-dialog">
        <div class="app-modal-content">
          <button class="app-modal-close" type="button" @click="handleClose">
            <TheCrossIcon />
          </button>

          <div class="app-modal-header">
            <header class="control-header">
              <h3 class="control-header__title">Change the order of {{ group.name }}</h3>
            </header>
          </div>

          <div ref="appModalBody" class="app-modal-body">
            <ArtGuideEventsReorderControlEventsList
              ref="eventsList"
              v-model="orderedEvents"
              :scrollable="isEventsListScrollable"
            />
          </div>

          <div class="app-modal-footer">
            <ArtGuideEventsReorderControlActions
              :disable-saving="isOrderNotChanged"
              @close="handleClose"
              @save="handleSave"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { areArraysEqual } from '@/helpers/arrayData';
import ArtGuideEventsReorderControlEventsList from '@/components/artguide/ArtGuideEventsReorderControlEventsList.vue';
import ArtGuideEventsReorderControlActions from '@/components/artguide/ArtGuideEventsReorderControlActions.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  components: {
    TheCrossIcon,
    ArtGuideEventsReorderControlEventsList,
    ArtGuideEventsReorderControlActions,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderedEvents: [],
      isEventsListScrollable: false,
    };
  },
  computed: {
    isOrderNotChanged() {
      return areArraysEqual(this.initEventIds, this.orderedEventIds);
    },
    initEventIds() {
      return this.group.events.map((e) => e.id);
    },
    orderedEventIds() {
      return this.orderedEvents.map((e) => e.id);
    },
  },
  watch: {
    group(newGroup) {
      if (newGroup) {
        this.orderedEvents = newGroup.events;
      }
    },
  },
  methods: {
    handleTransitionEnter() {
      this.isEventsListScrollable =
        this.$refs.appModalBody &&
        this.$refs.eventsList &&
        this.$refs.eventsList.$el.offsetHeight > this.$refs.appModalBody.offsetHeight;
    },
    handleClose() {
      this.$emit('input', false);
    },
    handleSave() {
      if (this.isOrderNotChanged) {
        return;
      }
      this.$emit('saving', this.orderedEventIds);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-reorder-control {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(#000, 0.5);

  .app-modal-dialog {
    --app-agrc-modal-dialog-margin: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    height: calc(100% - var(--app-agrc-modal-dialog-margin) * 2);
    min-height: calc(100% - var(--app-agrc-modal-dialog-margin) * 2);
    margin: var(--app-agrc-modal-dialog-margin);
    pointer-events: none;

    @media (min-width: 800px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .app-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 64px 24px 32px;
    background-color: #fff;
    pointer-events: auto;
    outline: 0;

    @media (min-width: 577px) {
      padding-bottom: 40px;
    }

    @media (min-width: 768px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .app-modal {
    &-close {
      position: absolute;
      top: 18px;
      right: 10px;
      z-index: 100;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      @media (min-width: 441px) {
        right: 18px;
      }

      @media (min-width: 577px) {
        top: 24px;
        right: 24px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-header,
    &-footer {
      flex-shrink: 0;
    }

    &-header {
      padding-bottom: 32px;

      @media (min-width: 577px) {
        padding-bottom: 36px;
      }

      @media (min-width: 768px) {
        padding-bottom: 40px;
      }

      .control-header {
        padding-top: 8px;

        &__title {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 2px;
          text-transform: uppercase;

          @media (min-width: 577px) {
            font-size: 20px;
            letter-spacing: 3px;
          }

          @media (min-width: 768px) {
            font-size: 22px;
          }
        }
      }
    }

    &-body {
      flex: 1 1 auto;
      overflow-y: auto;

      p,
      a {
        color: #5b5d62;
      }
    }

    &-footer {
      padding-top: 40px;

      @media (min-width: 768px) {
        padding-top: 48px;
      }

      @media (min-width: 992px) {
        padding-top: 56px;
      }
    }
  }
}
</style>
