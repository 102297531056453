<template>
  <section class="art-guide-staff-pick-event">
    <AppRouteLink :class="{ 'art-guide-staff-pick-event__link': routeTo }" :route-to="routeTo">
      <header>
        <img class="art-guide-staff-pick-event__image" loading="lazy" :src="mediaUrl" :alt="event.eventable.title" />
        <h3 class="art-guide-staff-pick-event__title">{{ event.eventable.title }}</h3>
      </header>
    </AppRouteLink>
    <p v-if="event.eventable.address" class="art-guide-staff-pick-event__address">{{ event.eventable.address }}</p>
    <p v-if="dates" class="art-guide-staff-pick-event__dates">{{ dates }}</p>
  </section>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import AppRouteLink from '@/components/partials/AppRouteLink';
import { formatDate } from '@/helpers/dateTimeHelper';

export default {
  name: 'ArtGuideStaffPickEvent',
  components: { AppRouteLink },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.event.eventable);
    },
    routeTo() {
      return !this.event.is_expired ? getEntityRoute(this.event.eventable) : null;
    },
    dates() {
      if (!this.event.eventable.earliest_start_date) {
        return null;
      }

      const startDate = formatDate(this.event.eventable.earliest_start_date);
      const endDate = formatDate(this.event.eventable.latest_end_date);

      return endDate !== startDate ? `${startDate} - ${endDate}` : startDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-staff-pick-event {
  &__link {
    color: inherit;
  }
  &__link:hover &__title {
    opacity: 0.7;
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  &__address {
    margin-top: 8px;
    margin-bottom: 0;
    color: #5b5d62;
    text-align: justify;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
  }

  &__dates {
    margin-top: 8px;
    margin-bottom: 0;
    color: #a2a2a2;
    font-size: 14px;
  }
}
</style>
