<template>
  <button v-on="$listeners" class="action" type="button">
    <AppTrashIcon />
  </button>
</template>

<script>
import AppTrashIcon from '@/components/icons/AppTrashIcon.vue';

export default {
  components: { AppTrashIcon },
};
</script>

<style lang="scss" scoped>
.action {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0;

  &:hover {
    opacity: 0.7;
  }
}
</style>
