<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M11.8149 30.4028H27.1851C27.3942 30.4034 27.5951 30.4823 27.7465 30.6233C27.8979 30.7642 27.9882 30.9564 27.9989 31.1604C28.0096 31.3644 27.9399 31.5647 27.8041 31.72C27.6683 31.8754 27.4767 31.974 27.2688 31.9955L27.1851 32H11.8149C11.6058 31.9993 11.4049 31.9204 11.2535 31.7795C11.1021 31.6386 11.0118 31.4463 11.0011 31.2423C10.9904 31.0383 11.0601 30.8381 11.1959 30.6828C11.3317 30.5274 11.5233 30.4288 11.7312 30.4072L11.8149 30.4028H27.1851H11.8149ZM19.2528 9.00383L19.3365 9C19.7597 9 20.1083 9.31433 20.1501 9.71683L20.154 9.79861V25.3172L23.8475 21.7101C23.9229 21.6338 24.0131 21.573 24.1129 21.5312C24.2127 21.4893 24.3199 21.4673 24.4285 21.4664C24.537 21.4655 24.6447 21.4858 24.7452 21.526C24.8456 21.5662 24.9369 21.6255 25.0136 21.7005C25.0904 21.7755 25.1511 21.8647 25.1921 21.9628C25.2332 22.061 25.2539 22.1662 25.2529 22.2722C25.2519 22.3782 25.2293 22.483 25.1865 22.5804C25.1436 22.6779 25.0813 22.766 25.0032 22.8396L19.916 27.8089C19.7627 27.9585 19.5548 28.0426 19.3381 28.0426C19.1214 28.0426 18.9136 27.9585 18.7603 27.8089L13.6724 22.8396C13.5191 22.6898 13.4329 22.4867 13.4329 22.2748C13.4329 22.063 13.5191 21.8598 13.6724 21.7101C13.8258 21.5603 14.0337 21.4761 14.2506 21.4761C14.4675 21.4761 14.6754 21.5603 14.8288 21.7101L18.5189 25.3147V9.79861C18.5189 9.38525 18.8407 9.04472 19.2528 9.00383L19.3365 9L19.2528 9.00383Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'AppDownloadIcon',
};
</script>
