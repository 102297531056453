<template>
  <div class="art-guide-events-group-item-statuses">
    <div v-if="event.eventable.accessType">
      <ArtGuideEventAccessType class="access-type" :type="event.eventable.accessType.type" />
    </div>
    <div v-if="event.activity_status || event.eventable.based_type" class="text-statuses">
      <div v-if="event.eventable.based_type" class="text-status text-status--event-based-type">
        {{ event.eventable.based_type }}
      </div>
      <div v-if="event.activity_status" class="text-status text-status--activity">
        {{ event.activity_status }}
      </div>
    </div>
  </div>
</template>

<script>
import ArtGuideEventAccessType from '@/components/artguide/ArtGuideEventAccessType.vue';

export default {
  components: {
    ArtGuideEventAccessType,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item-statuses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 16px;

  @media (min-width: 577px) {
    width: 100%;
    justify-content: end;
    gap: 32px;
  }

  .access-type {
    width: 24px;
    height: 24px;

    @media (min-width: 577px) {
      width: 32px;
      height: 32px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .text-statuses {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: end;
    gap: 16px;

    @media (min-width: 577px) {
      width: auto;
      gap: 32px;
    }
  }

  .text-status {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    white-space: nowrap;

    @media (min-width: 577px) {
      font-size: 16px;
    }

    &--activity {
      color: #7b61ff;
    }
    &--event-based-type {
      color: #a2a2a2;
    }
  }
}
</style>
