


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { getEntityRoute } from '@/helpers/entityType';
import MyArtGuidesPageItemCreatorDatesInfo from '@/components/artguide/MyArtGuidesPageItemCreatorDatesInfo.vue';

@Component({
  components: { MyArtGuidesPageItemCreatorDatesInfo },
})
export default class MyArtGuidesPageItem extends Vue {
  @Prop() item!: any;

  get media() {
    return this.item.media?.url;
  }

  get routeTo() {
    return getEntityRoute(this.item);
  }
}
