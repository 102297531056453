<template>
  <section class="art-guide-events-group-item">
    <header>
      <div class="art-guide-events-group-item__event-type-wrapper">
        <div class="art-guide-events-group-item__event-type">{{ event.eventable.type_for_human }}</div>
        <slot name="event-type-section-right-side" />
      </div>

      <div class="art-guide-events-group-item__header-common">
        <h3 class="art-guide-events-group-item__title">
          <AppRouteLink
            :class="{ 'art-guide-events-group-item__link': routeTo }"
            fallback-tag="span"
            :route-to="routeTo"
          >
            {{ event.eventable.title }}
          </AppRouteLink>
        </h3>
        <ArtGuideEventsGroupItemStatuses v-if="showStatuses" :event="event" />
      </div>
    </header>

    <figure class="art-guide-events-group-item__image-section">
      <AppRouteLink :route-to="routeTo">
        <img class="art-guide-events-group-item__image" loading="lazy" :src="mediaUrl" :alt="event.eventable.title" />
      </AppRouteLink>

      <ArtGuideEventsGroupItemActions
        :changing-favorite="changingFavorite"
        :is-detaching="isDetaching"
        :is-favorite="isFavorite"
        @changing-favorite="changeFavoriteStatus"
        @detaching-event="handleEventDetaching"
      />
      <span class="art-guide-events-group-item__position">{{ event.position }}</span>
    </figure>

    <AppCollapsibleContentSection v-if="event.eventable.description">
      <div class="art-guide-events-group-item__description">
        {{ event.eventable.description }}
      </div>
    </AppCollapsibleContentSection>

    <div class="art-guide-events-group-item__details-wrapper">
      <ArtGuideEventsGroupItemDetails v-if="showDetails" :event="event" />
      <ArtGuideEventsGroupItemNotes
        v-model="notes"
        class="art-guide-events-group-item__notes"
        :changing="notesChanging"
        @change="changeNotes"
      />
    </div>
  </section>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';
import changeArtGuideEventNotesMutation from '@/graphql/artGuide/ChangeArtGuideEventNotes.mutation.gql';

import AppRouteLink from '@/components/partials/AppRouteLink';
import AppCollapsibleContentSection from '@/components/AppCollapsibleContentSection.vue';
import ArtGuideEventsGroupItemStatuses from '@/components/artguide/ArtGuideEventsGroupItemStatuses.vue';
import ArtGuideEventsGroupItemNotes from '@/components/artguide/ArtGuideEventsGroupItemNotes.vue';
import ArtGuideEventsGroupItemDetails from '@/components/artguide/ArtGuideEventsGroupItemDetails.vue';
import ArtGuideEventsGroupItemActions from '@/components/artguide/ArtGuideEventsGroupItemActions.vue';

export default {
  name: 'ArtGuideEventsGroupItem',
  components: {
    ArtGuideEventsGroupItemActions,
    ArtGuideEventsGroupItemDetails,
    ArtGuideEventsGroupItemNotes,
    ArtGuideEventsGroupItemStatuses,
    AppCollapsibleContentSection,
    AppRouteLink,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      changingFavorite: false,
      isFavorite: this.event.eventable.is_favorite_by_current_user,
      notes: this.event.notes,
      notesChanging: false,
      needAllDesc: false,
    };
  },

  computed: {
    isDetaching() {
      return (
        this.$store.state.artGuide.isActiveEventDetaching &&
        this.$store.state.artGuide.eventDetachingId === this.event.id
      );
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.event.eventable);
    },
    routeTo() {
      return !this.event.is_expired ? getEntityRoute(this.event.eventable) : null;
    },
    showDetails() {
      return (
        this.isDatesPresent || this.isTimeHoursPresent || this.event.eventable.address || this.event.eventable.artSpace
      );
    },
    isDatesPresent() {
      return this.event.eventable.earliest_start_date;
    },
    isTimeHoursPresent() {
      return (
        this.isDatesPresent ||
        (this.event.eventable.openingHourSeasons && this.event.eventable.openingHourSeasons.length)
      );
    },
    showStatuses() {
      return this.event.eventable.accessType || this.event.activity_status || this.event.eventable.based_type;
    },
  },

  methods: {
    changeFavoriteStatus() {
      if (this.changingFavorite) {
        return;
      }

      this.changingFavorite = true;

      if (!this.isFavorite) {
        this.$apollo
          .mutate({
            mutation: addToUserFavoritesMutation,
            variables: {
              entities: [{ type: this.event.eventable.__typename, id: this.event.eventable.id }],
            },
          })
          .then(({ data }) => {
            this.changingFavorite = false;
            this.isFavorite = data.addToUserFavorites;
            this.$emit('favorite-changed');
          })
          .catch(() => {
            this.changingFavorite = false;
          });
        return;
      }

      this.$apollo
        .mutate({
          mutation: deleteFromUserFavoritesMutation,
          variables: {
            entities: [{ type: this.event.eventable.__typename, id: this.event.eventable.id }],
          },
        })
        .then(({ data }) => {
          this.changingFavorite = false;
          this.isFavorite = !data.deleteFromUserFavorites;
          this.$emit('favorite-changed');
        })
        .catch(() => {
          this.changingFavorite = false;
        });
    },
    changeNotes() {
      if (this.notes === this.event.notes) {
        return;
      }

      this.notesChanging = true;
      this.$apollo
        .mutate({
          mutation: changeArtGuideEventNotesMutation,
          variables: {
            id: this.event.id,
            notes: this.notes,
          },
        })
        .then(({ data }) => {
          this.notesChanging = false;

          if (!data.artGuideEvent) {
            this.notes = this.event.notes;
            return;
          }

          this.notes = data.artGuideEvent.notes;
          this.$emit('notes-changed');
        })
        .catch(() => {
          this.notesChanging = false;
          this.notes = this.event.notes;
        });
    },
    handleEventDetaching() {
      this.$store.dispatch('setArtGuideEventDetaching', { id: this.event.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item {
  &__event-type-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__event-type {
    padding: 6px 16px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #dadcde;
    background-color: #fff;

    @media (min-width: 577px) {
      padding: 8px 32px;
      font-size: 16px;
    }
  }

  &__header-common {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 12px 0;

    @media (min-width: 577px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.15em;
    line-height: normal;
    text-transform: uppercase;

    @media (min-width: 577px) {
      max-width: 600px;
      font-size: 18px;
    }
  }

  &__link {
    color: #363636;

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-section {
    position: relative;
    margin-bottom: 16px;

    @media (min-width: 577px) {
      margin-bottom: 24px;
    }
  }

  &__image {
    max-width: 100%;
    width: 100%;
    height: 180px;
    object-fit: cover;

    @media (min-width: 577px) {
      height: calc(100vh - 100px);
      max-height: 400px;
    }
  }

  &__position {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background-color: #363636;
    border-radius: 100%;

    @media (min-width: 577px) {
      bottom: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__description {
    font-size: 14px;
    text-align: justify;

    @media (min-width: 577px) {
      font-size: 16px;
    }
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 16px;

    > * {
      flex: 1;
    }

    @media (min-width: 599px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 48px;
      margin-top: 24px;
    }
  }

  &__notes {
    @media (min-width: 599px) {
      align-self: flex-start;
    }
  }
}
</style>
