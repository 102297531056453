<template>
  <dl class="art-guide-events-group-item-details">
    <template v-if="isDatesPresent">
      <dt>Dates</dt>
      <dd>{{ dateValues }}</dd>
    </template>

    <template v-if="isTimeHoursPresent">
      <dt>Time</dt>
      <dd v-if="event.eventable.openingHourSeasons && event.eventable.openingHourSeasons.length">
        <ArtGuideEventsGroupItemOpeningHourSeasons :seasons="event.eventable.openingHourSeasons" />
      </dd>
      <dd v-else-if="startTime">{{ timeValues }}</dd>
    </template>

    <template v-if="event.eventable.artSpace || event.eventable.address">
      <dt>Address</dt>
      <dd>
        <span v-if="event.eventable.artSpace">{{ event.eventable.artSpace.title }}</span>
        <span v-if="event.eventable.address">{{ event.eventable.address }}</span>
      </dd>
    </template>
  </dl>
</template>

<script>
import { formatDate, formatTime } from '@/helpers/dateTimeHelper';
import ArtGuideEventsGroupItemOpeningHourSeasons from '@/components/artguide/ArtGuideEventsGroupItemOpeningHourSeasons.vue';

export default {
  components: { ArtGuideEventsGroupItemOpeningHourSeasons },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDatesPresent() {
      return this.event.eventable.earliest_start_date;
    },
    isTimeHoursPresent() {
      return (
        this.isDatesPresent ||
        (this.event.eventable.openingHourSeasons && this.event.eventable.openingHourSeasons.length)
      );
    },
    dateValues() {
      return this.startDate + (this.startDate !== this.endDate ? ` - ${this.endDate}` : '');
    },
    timeValues() {
      return this.startTime + (this.startTime !== this.endTime ? ` - ${this.endTime}` : '');
    },
    startDate() {
      return formatDate(this.event.eventable.earliest_start_date);
    },
    startTime() {
      return formatTime(this.event.eventable.earliest_start_date);
    },
    endDate() {
      return formatDate(this.event.eventable.latest_end_date);
    },
    endTime() {
      const endDate = this.event.eventable.latest_end_date;
      return this.isDatesPresent && endDate ? formatTime(endDate) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-events-group-item-details {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(70%, 1fr);
  grid-auto-rows: minmax(24px, max-content);
  gap: 8px 16px;
  font-size: 14px;

  @media (min-width: 577px) {
    font-size: 16px;
  }

  span {
    display: block;
  }
}
</style>
