<template>
  <div class="wrapper">
    <div
      v-if="artGuides.length"
      class="d-flex justify-content-between align-items-center data-page-top"
      data-aos="fade-up"
    >
      <h2 class="page-title">My Art Guides</h2>
      <RouterLink class="see-all" :to="{ name: 'all-art-guides' }">See all</RouterLink>
    </div>

    <div class="row">
      <div v-for="guide in artGuides" :key="guide.id" class="col-lg-6 col-xl-4 pad-bottom" data-aos="fade-up">
        <ProfileMyArtGuidesItem :guide="guide" />
      </div>
    </div>
  </div>
</template>

<script>
import artGuidesDataQuery from '@/graphql/artGuide/ArtGuides.query.gql';

import ProfileMyArtGuidesItem from '@/components/partials/ProfileMyArtGuidesItem.vue';

export default {
  name: 'ProfileMyArtGuides',
  components: { ProfileMyArtGuidesItem },
  apollo: {
    artGuides: {
      query: artGuidesDataQuery,
      variables() {
        return {
          limit: 3,
          onlyIfEventsExist: true,
          withDates: false,
          withEvents: true,
        };
      },
      update: (data) => data.artGuidesData.data,
    },
  },
  data() {
    return {
      artGuides: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.see-all {
  color: #363636;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1.11px;
  text-decoration: underline;
  text-transform: uppercase;
}

.data-page-top {
  padding-bottom: 30px;
  margin-bottom: 0;
}

.pad-bottom {
  padding-bottom: 40px;
}

.page-title {
  margin-bottom: 0;
  color: #353535;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 2.86px;
  text-transform: uppercase;
}
</style>
