<template>
  <header class="art-guide-header-section">
    <div class="container">
      <h1 class="page-header-title">
        <span class="page-header-title__type">Art Guide</span>
        <span class="page-header-title__guide-title">{{ artGuide.title }}</span>
      </h1>
    </div>

    <figure v-if="artGuide.event_groups.length" class="page-header-media">
      <img v-if="artGuide.media" class="page-header-media__image" :src="artGuide.media.url" :alt="artGuide.title" />

      <div v-if="parsedStartDate && parsedEndDate" class="page-header-media__overlay">
        <div class="page-header-duration-info">
          <div class="guide-duration__dates">
            <div class="guide-duration-info">
              <span class="guide-duration-info__before">{{ parsedStartDate.weekDay }}</span>
              <span class="guide-duration-info__value">{{ parsedStartDate.date }}</span>
              <span class="guide-duration-info__after">{{ parsedStartDate.year }}</span>
            </div>
            <span class="page-header-duration-info__slash-separator" />
            <div class="guide-duration-info">
              <span class="guide-duration-info__before">{{ parsedEndDate.weekDay }}</span>
              <span class="guide-duration-info__value">{{ parsedEndDate.date }}</span>
              <span class="guide-duration-info__after">{{ parsedEndDate.year }}</span>
            </div>
          </div>
          <span class="page-header-duration-info__line-separator" />
          <div class="guide-duration__days">
            <div class="guide-duration-info">
              <span class="guide-duration-info__before">Duration</span>
              <span class="guide-duration-info__value">{{ durationInDays }}</span>
              <span class="guide-duration-info__after">Days</span>
            </div>
          </div>
        </div>
      </div>
    </figure>
  </header>
</template>

<script>
import { durationInDays, formatDate } from '@/helpers/dateTimeHelper';

export default {
  props: {
    artGuide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDate() {
      return this.artGuide.dates[0];
    },
    endDate() {
      return this.artGuide.dates[1];
    },
    parsedStartDate() {
      if (!this.startDate) {
        return null;
      }

      return {
        date: formatDate(this.startDate, {
          month: 'short',
          day: '2-digit',
        }),
        weekDay: formatDate(this.startDate, {
          weekday: 'long',
        }),
        year: formatDate(this.startDate, {
          year: 'numeric',
        }),
      };
    },
    parsedEndDate() {
      if (!this.endDate) {
        return null;
      }

      return {
        date: formatDate(this.endDate, {
          month: 'short',
          day: '2-digit',
        }),
        weekDay: formatDate(this.endDate, {
          weekday: 'long',
        }),
        year: formatDate(this.endDate, {
          year: 'numeric',
        }),
      };
    },
    durationInDays() {
      const duration = durationInDays(this.startDate, this.endDate);

      return duration > 0 ? duration : '< 1';
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-header-section {
  .page-header-title {
    margin: 0;
    padding: 47px 0 32px;
    text-align: center;
    text-transform: uppercase;

    &__type {
      display: block;
      margin-bottom: 12px;
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: 6px;
    }

    &__guide-title {
      display: block;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 3px;
    }
  }

  .page-header-media {
    position: relative;
    min-height: 160px;
    margin: 0;
    background-color: #363636;

    &__image {
      width: 100%;
      height: calc(100vh - 100px);
      max-height: 600px;
      object-fit: cover;
    }

    &__overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 48px 16px 20px;
      background: linear-gradient(to bottom, transparent, #000);
    }

    .page-header-duration-info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      @media (min-width: 577px) {
        gap: 20px;
      }

      &__slash-separator {
        width: 20px;
        height: 20px;
        background: url('~@/assets/images/art-guide/duration-info-slash-separator.svg') center no-repeat;
      }
      &__line-separator {
        width: 20px;
        height: 84px;
        background: url('~@/assets/images/art-guide/duration-info-line-separator.svg') center no-repeat;
      }
    }

    .guide-duration {
      &__dates {
        display: flex;
        gap: 12px;
        align-items: center;

        @media (min-width: 577px) {
          gap: 20px;
        }
      }

      &__days {
        @media (min-width: 577px) {
          padding-left: 10px;
        }
      }

      &-info {
        text-align: center;

        & > * {
          display: block;
        }

        &__before,
        &__after {
          color: #a2a2a2;
          font-size: 14px;
          line-height: 24px;

          @media (min-width: 577px) {
            font-size: 16px;
          }
        }

        &__before {
          font-weight: 500;
        }

        &__value {
          color: #fff;
          font-size: 22px;
          font-weight: 500;
          line-height: 36px;

          @media (min-width: 577px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
