<template>
  <Transition name="fade">
    <div v-if="value" class="search pt-0 pb-0" :class="{ 'search--mobile': isMobileScreen }">
      <div class="container">
        <!-- Search field -->
        <div class="col-12">
          <div class="search-field p-3">
            <input
              id="search-input-field"
              ref="searchField"
              v-model.trim="searchField"
              class="search-field__input"
              placeholder="START TYPING TO SEARCH..."
              type="text"
              @focus="isShowAutoSuggest = true"
              @input="searchWithDebounce"
              @paste="searchWithDebounce"
            />
            <button
              v-show="isMobileScreen ? true : !!searchField"
              class="search-field__close"
              type="button"
              @click="isMobileScreen ? handleClose() : clearEverything()"
            >
              <TheCrossIcon class="default-stroke" />
            </button>

            <Transition name="slide-up">
              <div
                v-if="isShowAutoSuggest && !isMobileScreen && autoSuggestResults.length"
                v-click-outside="handleClickOutsideAutoSuggestList"
                class="auto-suggest w-100"
              >
                <ul class="suggested_text-outer">
                  <li v-for="(item, idx) in autoSuggestResults" :key="`search-auto-suggest-list-${idx}`">
                    <AppSearchAutoSuggestListItem :item="item.object" />
                  </li>
                </ul>
              </div>
            </Transition>

            <div
              v-if="!isSomethingFound && isSearchDone && !!searchField"
              class="not-found d-flex justify-content-center w-100"
            >
              0 results found
              <div class="mobile-desciption">
                If there's something you want to see or do and you can't find it in our app, just give us a call and
                we'll do our best to make it happen for you.
              </div>
            </div>
            <div v-if="isLoading" class="not-found d-flex justify-content-center w-100">Loading...</div>
          </div>
        </div>

        <!-- Site page link -->
        <div v-if="sitePages.length" class="col-12" @click="handleClose">
          <AppSearchResultSitePage v-for="p in sitePages" :key="`search-site-page-${p.id}`" :entity="p" />
        </div>

        <!-- Destination link -->
        <div v-if="destinations.length" class="col-12" @click="handleClose">
          <AppSearchResultSitePage
            v-for="d in destinations"
            :key="`search-destination-${d.slug}`"
            :entity="d"
            hint="View destination page"
          />
        </div>

        <!-- Results -->
        <div v-if="isSearchDone && isSomethingFound" class="col-12">
          <div class="search-results">
            <div class="search-results-menu">
              <div v-if="!isMobileScreen">
                <AppEventSlider
                  v-for="r in filteredResults"
                  :key="`search_results_${r.group}`"
                  :appearing-with-animation="false"
                  :destination="onlyOneDestination"
                  :events="r.objects"
                  :search-results="true"
                  :show-see-all-link="!!onlyOneDestination"
                  :title="r.group"
                />
              </div>

              <template v-else>
                <swiper
                  id="remove-swiper-container"
                  ref="mySwiper"
                  class="search-results-tabs shadow-mobile px-3 w-100 d-flex d-xl-none d-lg-none"
                  :options="swiperOptions"
                >
                  <div
                    v-for="(r, rIdx) in filteredResults"
                    :key="`search_results_tab_${r.group}`"
                    class="swiper-slide search-results-tabs__tab"
                    :class="{ 'active-tab': activeTabIdx === rIdx }"
                    :style="{ width: getTextWidth(`${r.group} (${r.objects.length})`) + 'px' }"
                    @click="switchTab(rIdx)"
                  >
                    {{ r.group }} ({{ r.objects.length }})
                  </div>
                </swiper>

                <div class="search-results-tab-content">
                  <TransitionGroup class="search-results-tab-content__list item-content" name="list" tag="ul">
                    <li
                      v-for="item in activeTabObjects"
                      :key="`search-results-tab-${item.__typename}_${item.id}`"
                      class="item"
                    >
                      <AppSearchResultItem :item="item" />
                    </li>
                  </TransitionGroup>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import _debounce from 'lodash/debounce';
import { EntityTypes } from '@/helpers/entityType';

import globalSearchResultsQuery from '@/graphql/GlobalSearchResults.query.gql';

import AppEventSlider from '@/components/partials/sliders/AppEventSlider';
import AppSearchResultItem from './AppSearchResultItem';
import AppSearchAutoSuggestListItem from '@/components/partials/search/AppSearchAutoSuggestListItem';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import AppSearchResultSitePage from '@/components/partials/search/AppSearchResultSitePage.vue';

export default {
  name: 'AppSearch',
  components: {
    AppSearchResultSitePage,
    TheCrossIcon,
    AppSearchAutoSuggestListItem,
    AppEventSlider,
    AppSearchResultItem,
  },
  props: {
    initDestinationId: {
      type: Number,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      autoSuggestResults: [],
      searchField: '',

      isShowAutoSuggest: false,
      swiperOptions: {
        slidesPerView: 'auto',
      },
      isSearchDone: false,
      activeTabIdx: null,
      isLoading: false,
    };
  },

  computed: {
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.swiper : null;
    },
    isSomethingFound() {
      return this.autoSuggestResults.length;
    },
    activeTabObjects() {
      const group = this.filteredResults.find((r, rIdx) => rIdx === this.activeTabIdx);

      return group ? group.objects : [];
    },

    filteredResults() {
      return this.autoSuggestResults
        .filter((r) => !['destinations', 'member site pages'].includes(r.group.toLowerCase()))
        .reduce((acc, r) => {
          const key = r.group;
          const groupIdx = acc.findIndex((gr) => gr.group === key);

          if (groupIdx === -1) {
            acc.push({ group: key, objects: [r.object] });
          } else {
            acc[groupIdx].objects.push(r.object);
          }

          return acc;
        }, []);
    },

    destinations() {
      if (this.initDestinationId) {
        return [];
      }
      return this.autoSuggestResults.filter((r) => r.group.toLowerCase() === 'destinations').map((r) => r.object);
    },
    sitePages() {
      return this.autoSuggestResults.filter((r) => r.group.toLowerCase() === 'member site pages').map((r) => r.object);
    },
    onlyOneDestination() {
      return this.destinations.length === 1 ? this.destinations[0] : null;
    },
  },

  watch: {
    $route() {
      this.handleClose();
    },
    value(newValue) {
      if (newValue) {
        this.$nextTick().then(() => {
          this.focus();
        });
      } else {
        this.clearDataField();
        this.clearSearchField(false);
      }
    },
  },

  methods: {
    handleClickOutsideAutoSuggestList(event) {
      if (event.target.id !== 'search-input-field') {
        this.isShowAutoSuggest = false;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    focus() {
      this.$refs.searchField.focus();
    },
    searchWithDebounce: _debounce(function () {
      this.clearDataField();
      this.isSearchDone = false;
      this.search();
    }, 1000),

    search() {
      if (!this.searchField) {
        return;
      }
      this.isLoading = true;
      this.$apollo
        .query({
          query: globalSearchResultsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            searchText: this.searchField.toLowerCase(),
            destinationId: this.initDestinationId,
          },
        })
        .then(({ data }) => {
          if (data && data.globalSearchResults && data.globalSearchResults.length) {
            this.autoSuggestResults = data.globalSearchResults.filter(
              (r) => r.type_from_object !== EntityTypes.virtual_library_item
            );
            this.switchTab(0);
          }
          this.isSearchDone = true;
          this.isLoading = false;
        });
    },
    switchTab(tabIdx) {
      this.activeTabIdx = tabIdx;
    },
    getTextWidth(text) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '500 12px "Futura PT"';
      const metrics = context.measureText(text);

      return metrics.width + text.length * 1.71 + 13;
    },
    clearEverything() {
      this.clearDataField();
      this.clearSearchField();
    },
    clearDataField() {
      this.autoSuggestResults = [];
      this.activeTabIdx = null;
      this.isSearchDone = false;
    },
    clearSearchField(isFocus = true) {
      this.searchField = '';

      if (isFocus) {
        this.focus();
      }
    },
  },
};
</script>
