<template>
  <div class="art-guide-page-wrapper">
    <div v-if="loader" class="app-loader-wrapper">
      <AppLoader />
    </div>
    <div v-if="!loader && !artGuide" class="container">
      <MsgEntityNotFound name="Art Guide" />
    </div>

    <div v-else-if="artGuide" class="art-guide">
      <BackHeader
        :description="artGuide.description"
        :is-static="false"
        skip-destination-query
        :title="artGuide.title"
        with-filter
      />

      <ArtGuideHeaderSection :art-guide="artGuide" />

      <main>
        <div class="art-guide__actions">
          <div class="page-container page-container--smaller">
            <div class="guide-actions">
              <div class="guide-actions__common">
                <AppDownloadAction v-if="!isEmptyArtGuide" @click="exportArtGuidePdf" />
                <AppDeleteAction @click="showConfirmDeleteArtGuideModal = true" />
              </div>
              <AppDataFilterOpenButton
                v-if="filterGroups.length"
                :appearing-with-animation="false"
                @click="handleOpenFilter"
              />
            </div>

            <MsgEntityCollectionEmpty
              v-if="isEmptyArtGuide"
              class="art-guide__message-no-events"
              message="Nothing has been added to this Art Guide yet."
            />
          </div>
        </div>

        <div v-if="!isEmptyArtGuide" class="page-container">
          <ArtGuideIntroductorySection :art-guide="artGuide" />

          <ArtGuidePageSection v-if="artGuide.staff_pick_events.length">
            <ArtGuideStaffPickEventsSection :events="artGuide.staff_pick_events" />
          </ArtGuidePageSection>

          <ArtGuidePageSection v-for="group in artGuide.event_groups" :key="group.type">
            <ArtGuideEventsGroup
              :group="group"
              @favorite-changed="fetchArtGuide"
              @notes-changed="fetchArtGuide"
              @reorder="reorderGroupEvents"
            />
          </ArtGuidePageSection>
        </div>
      </main>
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    />

    <Transition name="slide-up">
      <AppConfirmationModal
        v-if="showConfirmDeleteArtGuideModal"
        :show="showConfirmDeleteArtGuideModal"
        header="Delete the Art Guide"
        @close="handleConfirmDeleteArtGuideModal"
      />
    </Transition>

    <Transition name="slide-up">
      <AppConfirmationModal
        v-if="showConfirmDetachEventFromArtGuideModal"
        :show="showConfirmDetachEventFromArtGuideModal"
        header="Detach this event from the Art Guide"
        @close="handleConfirmDetachEventFromArtGuideModal"
      />
    </Transition>

    <ArtGuideEventsReorderControl
      v-model="showReorderModal"
      :group="artGuideEventsGroupToReorder"
      @close="closeReorderControl"
      @saving="updateGroupEventsOrder"
    />
  </div>
</template>

<script>
import { exportArtGuideToPDF } from '@/api/exportToFile';
import ArtGuideDataFilterHelper from '@/helpers/data-filter/ArtGuideDataFilterHelper';
import { ERR_DEFAULT_MSG } from '@/helpers/errorHandler';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

import artGuideQuery from '@/graphql/artGuide/ArtGuide.single.query.gql';
import artGuideEntityTypesQuery from '@/graphql/artGuide/ArtGuideEntityTypes.query.gql';
import deleteArtGuideMutation from '@/graphql/artGuide/DeleteArtGuide.mutation.gql';
import deleteEntityFromArtGuideMutation from '@/graphql/artGuide/DeleteEntityFromArtGuide.mutation.gql';
import { storeOrderOfArtGuideEvents } from '@/api/artGuide';

import BackHeader from '@/components/partials/BackHeader';
import AppSectionWithViewToggling from '@/components/partials/elements/AppSectionWithViewToggling';
import ArtGuideEventsGroup from '@/components/artguide/ArtGuideEventsGroup';
import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import ArtGuideStaffPickEvent from '@/components/artguide/ArtGuideStaffPickEvent';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import MsgEntityCollectionEmpty from '@/components/MsgEntityCollectionEmpty.vue';
import AppDownloadAction from '@/components/AppDownloadAction.vue';
import AppDeleteAction from '@/components/AppDeleteAction.vue';
import ArtGuidePageSection from '@/components/artguide/ArtGuidePageSection.vue';
import ArtGuideStaffPickEvents from '@/components/artguide/ArtGuideStaffPickEvents.vue';
import AppConfirmationModal from '@/components/modals/AppConfirmationModal.vue';
import ArtGuideIntroductorySection from '@/components/artguide/ArtGuideIntroductorySection.vue';
import ArtGuideHeaderSection from '@/components/artguide/ArtGuideHeaderSection.vue';
import ArtGuideStaffPickEventsSection from '@/components/artguide/ArtGuideStaffPickEventsSection.vue';
import ArtGuideEventsReorderControl from '@/components/artguide/ArtGuideEventsReorderControl.vue';

export default {
  name: 'MyArtGuideSinglePage',
  components: {
    ArtGuideEventsReorderControl,
    ArtGuideStaffPickEventsSection,
    ArtGuideHeaderSection,
    ArtGuideIntroductorySection,
    AppDeleteAction,
    AppConfirmationModal,
    ArtGuideStaffPickEvents,
    ArtGuidePageSection,
    AppDownloadAction,
    MsgEntityCollectionEmpty,
    MsgEntityNotFound,
    ArtGuideStaffPickEvent,
    AppDataFilterOpenButton,
    AppDataFilterMain,
    ArtGuideEventsGroup,
    AppSectionWithViewToggling,
    BackHeader,
  },
  metaInfo() {
    return {
      title: `Art Guide: ${this.artGuide?.title}`,
    };
  },

  data() {
    return {
      artGuide: null,
      artGuideEventsGroupToReorder: {},
      artGuideFetching: true,
      artGuideDeleting: false,
      filterGroups: [],
      filterValues: {
        types: [],
      },
      isFilterValuesChanged: false,
      showConfirmDeleteArtGuideModal: false,
      showConfirmDetachEventFromArtGuideModal: false,
      showReorderModal: false,
    };
  },

  computed: {
    filterQueryVariables() {
      return ArtGuideDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isEmptyArtGuide() {
      return !this.artGuideFetching && !this.artGuide.event_groups.length && !this.isFilterDirty;
    },
    isEventDetaching() {
      return this.$store.state.artGuide.eventDetachingId !== null;
    },
    isFilterDirty() {
      return ArtGuideDataFilterHelper.isDirty(this.filterValues);
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    loader() {
      return !!this.$store.state.loader;
    },
  },

  watch: {
    $route() {
      this.fetchArtGuide();
    },
    isEventDetaching(isDetaching) {
      if (isDetaching) {
        this.showConfirmDetachEventFromArtGuideModal = true;
      }
    },
  },

  created() {
    this.$store.dispatch('setLoader', true);
    this.fetchArtGuide();
    this.fetchFilterData();
    this.$root.$on('openPDF', this.exportArtGuidePdf);
  },
  beforeDestroy() {
    this.$store.dispatch('clearArtGuideEventDetaching');
    this.$root.$off('openPDF', this.exportArtGuidePdf);
  },

  methods: {
    fetchArtGuide(onlyEvents = false) {
      const variables = prepareVariablesForSingleEntityQuery(this.$route.params);

      this.artGuideFetching = true;
      this.$apollo
        .query({
          query: artGuideQuery,
          fetchPolicy: 'no-cache',
          variables: {
            ...variables,
            ...this.filterQueryVariables,
            onlyEvents,
          },
        })
        .then(({ data }) => {
          const artGuide = data?.artGuide || null;

          if (variables.onlyId && artGuide?.id) {
            this.$router.replace(
              redirectToSingleEntityRoute(this.$route.name, artGuide.id, variables.slug, { query: this.$route.query })
            );
            return;
          }

          if (artGuide) {
            if (onlyEvents) {
              this.artGuide.staff_pick_events = artGuide.staff_pick_events;
              this.artGuide.event_groups = artGuide.event_groups;
            } else {
              this.artGuide = artGuide;
            }
          }

          this.artGuideFetching = false;
          this.$store.dispatch('setLoader', false);
        });
    },
    fetchFilterData() {
      const { slug } = prepareVariablesForSingleEntityQuery(this.$route.params);
      if (!slug) {
        return;
      }

      this.$apollo
        .query({
          query: artGuideEntityTypesQuery,
          fetchPolicy: 'no-cache',
          variables: {
            slug,
          },
        })
        .then(({ data = { artGuideEntityTypes: [] } }) => {
          this.filterGroups = ArtGuideDataFilterHelper.prepareFilterGroups({ types: data.artGuideEntityTypes });
        });
    },

    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
      this.isFilterValuesChanged = false;
    },
    handleChangeFilterValues() {
      this.isFilterValuesChanged = true;
      this.fetchArtGuide(true);
    },

    exportArtGuidePdf() {
      this.$store.dispatch('setLoader', true);

      exportArtGuideToPDF({
        id: this.artGuide.id,
        title: this.artGuide.title,
      })
        .catch(() => {
          this.$toast.error(
            'Unfortunately, you could not download the Art Guide. Please contact with the administrator.'
          );
        })
        .finally(() => {
          this.$store.dispatch('setLoader', false);
        });
    },

    handleConfirmDeleteArtGuideModal(answer) {
      if (this.artGuideDeleting) {
        return;
      }
      if (!answer) {
        this.showConfirmDeleteArtGuideModal = false;
        return;
      }

      this.artGuideDeleting = true;
      this.showConfirmDeleteArtGuideModal = false;
      this.$store.dispatch('setLoader', true);

      this.$apollo
        .mutate({
          mutation: deleteArtGuideMutation,
          variables: {
            id: this.artGuide.id,
          },
        })
        .then(() => {
          this.$toast.success('The Art Guide has been deleted.');
          this.$router.replace({ name: 'all-art-guides' });
        })
        .catch((e) => {
          const error = e?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;
          this.$toast.error(error);
        })
        .finally(() => {
          this.artGuideDeleting = false;
          this.$store.dispatch('setLoader', false);
        });
    },

    async handleConfirmDetachEventFromArtGuideModal(answer) {
      if (this.$store.state.artGuide.isActiveEventDetaching) {
        return;
      }
      if (!answer) {
        this.showConfirmDetachEventFromArtGuideModal = false;
        this.$store.dispatch('clearArtGuideEventDetaching');
        return;
      }

      await this.$store.dispatch('setArtGuideEventDetaching', { isActive: true });
      this.showConfirmDetachEventFromArtGuideModal = false;

      this.$apollo
        .mutate({
          mutation: deleteEntityFromArtGuideMutation,
          variables: {
            id: this.$store.state.artGuide.eventDetachingId,
          },
        })
        .then(() => {
          this.$toast.success('The event has been detached.');
          this.$store.dispatch('setLoader', true);
          this.fetchArtGuide();
          this.fetchFilterData();
        })
        .catch((e) => {
          const error = e?.graphQLErrors?.[0]?.message || ERR_DEFAULT_MSG;
          this.$toast.error(error);
        })
        .finally(() => {
          this.$store.dispatch('clearArtGuideEventDetaching');
        });
    },

    reorderGroupEvents(groupType) {
      const group = this.artGuide.event_groups.find((gr) => gr.type === groupType);

      if (!group || !group.events.length) {
        this.artGuideEventsGroupToReorder = {};
        return;
      }

      this.artGuideEventsGroupToReorder = {
        name: group.name,
        events: group.events.map((e) => ({
          id: e.id,
          title: e.eventable.title,
          media: e.eventable.media ? e.eventable.media[0] : null,
          position: e.position,
        })),
      };
      this.showReorderModal = true;
      this.$store.dispatch('disableScroll');
    },

    closeReorderControl() {
      this.$store.dispatch('enableScroll');
    },

    async updateGroupEventsOrder(eventIds) {
      try {
        this.$store.dispatch('setLoader', true);
        await storeOrderOfArtGuideEvents(this.artGuide.id, eventIds);
        this.fetchArtGuide();
      } catch (e) {
        this.$store.dispatch('setLoader', false);
        this.$toast.error(e.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-page-wrapper {
  padding-top: 60px;

  @media (min-width: 769px) {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .art-guide {
    padding-bottom: 100px;

    .page-container {
      $pageContainerMaxWidth: 888px;

      max-width: $pageContainerMaxWidth;
      margin: auto;
      padding: 0 24px;

      &--smaller {
        padding: 0 8px;

        @media (min-width: $pageContainerMaxWidth) {
          padding: 0 24px;
        }
      }
    }

    &__actions {
      padding: 40px 16px;
      box-shadow: 0 4px 8px 0 rgba(105, 105, 105, 0.1);

      .guide-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__common {
          display: flex;
          gap: 16px;
          align-items: center;
        }
      }
    }

    &__message-no-events {
      margin-top: 24px;
      text-align: center;
    }
  }
}
</style>
