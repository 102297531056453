<template>
  <div class="art-guide-reorder-control-actions">
    <button class="action active" :disabled="disableSaving" type="button" @click="$emit('save')">Save</button>
    <button class="action" type="button" @click="$emit('close')">Cancel</button>
  </div>
</template>

<script>
export default {
  props: {
    disableSaving: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-reorder-control-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 577px) {
    flex-direction: row;
    gap: 24px;
    justify-content: center;
  }

  .action {
    display: block;
    width: 100%;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #363636;
    border: 1px solid #363636;
    background-color: #fff;

    @media (min-width: 577px) {
      max-width: 180px;
    }

    &:not([disabled]) {
      &:hover {
        opacity: 0.7;
      }

      &.active {
        color: #fff;
        background-color: #363636;

        &:hover,
        &:focus {
          color: #fff !important;
        }
      }
    }

    &[disabled] {
      background-color: #dadcde;
      border-color: #dadcde;
      opacity: 0.7;
    }
  }
}
</style>
