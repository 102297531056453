<template>
  <div class="art-guide-staff-pick-events">
    <div class="position-relative">
      <swiper class="swiper" :options="swiperOptions">
        <swiper-slide v-for="event in events" :key="`art-guide-staff-picks-${event.id}`">
          <ArtGuideStaffPickEvent class="art-guide-staff-pick-events__event" :event="event" />
        </swiper-slide>
      </swiper>

      <template v-if="showNavButtons">
        <button class="app-slider__nav-btn app-slider__nav-btn--prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M22 12L14 20L22 28"
              stroke="#363636"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button class="app-slider__nav-btn app-slider__nav-btn--next">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <path
              d="M18 28L26 20L18 12"
              stroke="#363636"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import ArtGuideStaffPickEvent from '@/components/artguide/ArtGuideStaffPickEvent.vue';

const SLIDES_PER_VIEW = 2;

export default {
  components: { ArtGuideStaffPickEvent },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      swiperOptions: {
        centeredSlides: false,
        initialSlide: 0,
        preloadImages: false,
        speed: 1000,
        slidesPerView: SLIDES_PER_VIEW,
        spaceBetween: 48,
        breakpoints: {
          576: {
            slidesPerView: 1.2,
            spaceBetween: 28,
          },
          991: {
            slidesPerView: 1.8,
            spaceBetween: 36,
          },
        },
        loop: false,
        navigation: {
          nextEl: '.app-slider__nav-btn--next',
          prevEl: '.app-slider__nav-btn--prev',
        },
      },
    };
  },
  computed: {
    showNavButtons() {
      return this.events.length > SLIDES_PER_VIEW;
    },
  },
};
</script>

<style lang="scss" scoped>
.art-guide-staff-pick-events {
  position: relative;

  &__event {
    ::v-deep img {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }
  }

  .app-slider {
    &__nav-btn {
      --art-guide-staff-pick-events-slider-nav-btn-size: 60px;

      display: none;
      position: absolute;
      top: 0;
      z-index: 1;
      padding: 0;
      border: none;
      background-color: transparent;

      @media (min-width: 992px) {
        display: flex;
        align-items: center;
        width: var(--art-guide-staff-pick-events-slider-nav-btn-size);
        height: 180px;
      }

      &--prev {
        left: calc(var(--art-guide-staff-pick-events-slider-nav-btn-size) * -1);
        justify-content: flex-start;
      }

      &--next {
        right: calc(var(--art-guide-staff-pick-events-slider-nav-btn-size) * -1);
        justify-content: flex-end;
      }

      &:hover {
        opacity: 0.75;
        transition: all 0.2s;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      &.swiper-button-disabled {
        svg {
          path {
            stroke: #a2a2a2;
          }
        }
      }
    }
  }
}
</style>
