<template>
  <ArtGuideEventAccessTypeCardIcon v-if="type === 'card'" />
  <ArtGuideEventAccessTypeFreeIcon v-else-if="type === 'free'" />
  <ArtGuideEventAccessTypeTicketIcon v-else-if="type === 'purchase_tickets' || type === 'reserve_tickets'" />
  <span v-else />
</template>

<script>
export default {
  components: {
    ArtGuideEventAccessTypeCardIcon: () => import('@/components/artguide/ArtGuideEventAccessTypeCardIcon'),
    ArtGuideEventAccessTypeFreeIcon: () => import('@/components/artguide/ArtGuideEventAccessTypeFreeIcon'),
    ArtGuideEventAccessTypeTicketIcon: () => import('@/components/artguide/ArtGuideEventAccessTypeTicketIcon'),
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
