<template>
  <div class="see-details">
    <div class="head p-4">
      <div v-if="entity.title" class="title">{{ entity.title }}</div>
      <div class="close cursor" @click="$emit('close')">
        <TheCrossIcon />
      </div>
    </div>

    <div class="body px-4">
      <div class="body-content">
        <p class="see-details__type">{{ typeOrCategory }}</p>
        <p v-show="entity.address">{{ entity.address }}</p>
        <p v-show="entity.phone">{{ entity.phone }}</p>
      </div>

      <div class="see-details__actions">
        <button class="see-details__action" type="button" @click="$emit('get-map-direction')">
          <TheDirectionIcon />
          <span>Get directions</span>
        </button>

        <button
          v-if="areAccessInstructionsPresent"
          class="see-details__action"
          type="button"
          @click="$emit('show-access-instructions')"
        >
          <TheAccessIcon />
          <span>Access instructions</span>
        </button>
      </div>
    </div>

    <slot name="see-details-link">
      <RouterLink v-if="routeTo" class="details" :to="routeTo">See details</RouterLink>
    </slot>
  </div>
</template>

<script>
import { EntityTypes, getEntityRoute } from '@/helpers/entityType';

import TheAccessIcon from '@/components/icons/TheAccessIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import TheDirectionIcon from '@/components/icons/TheDirectionIcon.vue';

export default {
  name: 'DestinationMapEntityDetails',
  components: { TheAccessIcon, TheCrossIcon, TheDirectionIcon },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    areAccessInstructionsPresent() {
      return !!this.entity.accessInstructions?.length;
    },
    routeTo() {
      return getEntityRoute(this.entity);
    },
    typeOrCategory() {
      return this.entity.__typename === EntityTypes.art_space
        ? this.entity.type
        : this.entity.category || this.entity.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.see-details {
  $seeDetails: &;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 105;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  background-color: #fff;

  @media (min-width: 768px) {
    top: 0;
    left: auto;
    width: 360px;
    display: flex;
    margin-top: 0;
  }

  @media (min-width: 1200px) {
    width: 600px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title {
      color: #353535;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 3.43px;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 550px) {
      flex-direction: row;
    }

    @media (min-width: 768px) {
      flex-direction: column;
    }

    &-content {
      padding-bottom: 20px;

      @media (min-width: 550px) {
        padding-bottom: 30px;
      }

      @media (min-width: 768px) {
        padding-bottom: 0;
      }
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #acacac;

      @media (min-width: 768px) {
        margin-bottom: 20px;
        font-size: 22px;
        line-height: 32px;
      }

      &#{$seeDetails}__type {
        margin-bottom: 20px;

        @media (min-width: 768px) {
          margin-bottom: 60px;
        }
      }
    }
  }

  .cursor {
    cursor: pointer;
  }

  &__actions {
    display: flex;
    justify-content: center;

    @media (min-width: 768px) {
      flex-direction: column;
      margin-top: 80px;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 7px 30px;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    cursor: pointer;
    color: #acacac;
    text-align: center;

    @media (min-width: 550px) {
      margin-bottom: 20px;
    }

    @media (min-width: 768px) {
      margin-bottom: 40px;
      margin-left: 0;
      margin-right: 0;
      flex-direction: row;
      font-size: 20px;
    }

    svg {
      width: 20px;
      height: 20px;

      @media (min-width: 768px) {
        width: 32px;
        height: 32px;
      }
    }

    span {
      margin-left: 0;
      margin-top: 10px;

      @media (min-width: 768px) {
        margin-left: 25px;
        margin-top: 0;
      }
    }
  }

  .details {
    width: 100%;
    display: block;
    margin-top: auto;
    text-transform: uppercase;
    color: #363636;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2.86px;
    padding: 20px 0;
    border-radius: 0;
    text-align: center;
    transition: all 0.2s linear;
    position: relative;

    @media (min-width: 768px) {
      font-size: 20px;
    }

    &:hover {
      background-color: #363636;
      opacity: 1;
      color: #fff !important;
      cursor: pointer;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 1px;
      background-color: rgba(#363636, 0.1);
    }
  }
}
</style>
